import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    logs: [],
    logs_refferals: [],
    loading: 'IDLE',
    error: null
}


export const getLogs = createAsyncThunk(
    "log/get",
    async (params) => {
        let response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api-admin/get-logs?page=${params.page}&perPage=${params.perPage}&newPerPage=${params.newPerPage}&searchTerm=${params.searchTerm}`
        );
        console.log(response.data);
        return response.data;
    }
);

export const getLogsRefferals = createAsyncThunk(
    'log/get-refferals',
    async (params) => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/api-admin/get-logs-refferals?page=${params.page}&perPage=${params.perPage}&newPerPage=${params.newPerPage}&searchTerm=${params.searchTerm}`)
        console.log(response.data)
        return response.data
    }
)


export const logSlice = createSlice({
    name: 'log',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(getLogs.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })

            .addCase(getLogs.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.logs = action.payload.data
                state.error = null;
            })
            .addCase(getLogsRefferals.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.logs_refferals = action.payload.data
                state.error = null;
            })


            .addCase(getLogs.rejected, (state, action) => {
                state.logs = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })
    }
})

// Action creators are generated for each case reducer function
export const {} = logSlice.actions

export default logSlice.reducer
