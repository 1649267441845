import React from 'react'
import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {GridToolbarQuickFilter} from "@mui/x-data-grid-premium";

export default function CustomToolbar(props) {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarExport
                csvOptions={{
                    fileName: `${props.filename}${dayjs().format()}`,
                    delimiter: ';',
                    utf8WithBom: true,
                }}
                printOptions={{disableToolbarButton: true}}
            />

            <GridToolbarQuickFilter sx={{
                marginLeft: 'auto',
            }}/>
        </GridToolbarContainer>
    );
}
