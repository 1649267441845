import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, TextField} from "@mui/material";
import {useForm, Controller} from "react-hook-form";
import {login} from "../store/slices/authSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {useNavigate, Link} from "react-router-dom";
import axios from "axios";

function Login() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user)
    let navigate = useNavigate();

    const {control, handleSubmit} = useForm({
        defaultValues: {
            login: '',
            password: ''
        }
    });

    React.useEffect(() => {
        // console.log(user)
    }, [user])

    const onSubmit = data => {
        // dispatch(login(data))
        dispatch(login(data))
            .then(unwrapResult)
            .then((result) => {
                console.log(result); // => 233
                axios.defaults.headers.common['Authorization'] = `Bearer ${result.token.token}`;

                // navigate.push('/dashboard')
                navigate("/users", {replace: true});
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });
    };

    return (
        // <div className="container mt-3">
        <div className="d-flex align-items-center justify-content-center vh-100">
            <Card sx={{minWidth: 275, padding: '10px'}}>
                <div className="row">
                    <div className="col-12">
                        <h3>Авторизация</h3>
                    </div>
                    <div className="col-12">
                        <form>
                            <div>
                                <Controller
                                    name="login"
                                    control={control}
                                    render={({field}) => <TextField {...field} label="Логин" variant="outlined"/>}
                                />
                            </div>
                            <div className="mt-3">
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({field}) => <TextField {...field} type="password" label="Пароль"
                                                                    variant="outlined"/>}
                                />
                            </div>
                            <Button className="mt-3" variant="contained" onClick={handleSubmit(onSubmit)}>Войти</Button>
                            {/*<div>*/}
                            {/*    <Link to="/register" replace>Ещё не зарегистрированы?</Link>*/}
                            {/*</div>*/}
                        </form>
                    </div>
                </div>
            </Card>
        </div>
        // </div>
    );
}

export default Login;
