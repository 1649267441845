import { AccountCircle } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../store/slices/authSlice";

const pages = [
  {
    label: "Пользователи",
    link: "/users",
    roles: ["admin", "manager"],
  },
  {
    label: "Выплаты",
    link: "/operations",
    roles: ["admin"],
  },

  {
    label: "Транзакции",
    link: "/transactions",
    roles: ["admin", "manager"],
  },

  {
    label: "Статистика",
    link: "/statistics",
    roles: ["admin"],
  },
  {
    label: "Чистка BTC",
    link: "/requests",
    roles: ["admin", "manager"],
  },
  {
    label: "История",
    link: "/history",
    roles: ["admin", "manager"],
  },
  {
    label: "История рефералов",
    link: "/history_refferals",
    roles: ["admin", "manager"],
  },
  {
    label: "Настройки",
    link: "/settings",
    roles: ["admin"],
  },
  {
    label: "Администраторы",
    link: "/admins",
    roles: ["admin"],
  },
  {
    label: "Уведомления",
    link: "/notifications",
    roles: ["admin", "manager"],
  },
  {
    label: "Merchants",
    link: "/merchants_details",
    roles: ["admin", "manager"],
  },
];

function Header() {
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.auth.user).role.split(" ");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function logoutUser() {
    setAnchorEl(null);
    dispatch(logout());
    navigate("/login", { replace: true });
  }

  function profileUser() {
    setAnchorEl(null);
    navigate("/profile", { replace: true });
  }

  function settingsUser() {
    setAnchorEl(null);
    navigate("/settings", { replace: true });
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) =>
                roles.find((role) => page.roles.includes(role)) ? (
                  <MenuItem
                    key={page.label}
                    component={NavLink}
                    to={page.link}
                    onClick={handleCloseNavMenu}
                  >
                    <Typography textAlign="center">{page.label}</Typography>
                  </MenuItem>
                ) : null
              )}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            Панель администратора
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            {pages.map((page) =>
              roles.find((role) => page.roles.includes(role)) ? (
                <Button
                  component={NavLink}
                  variant="text"
                  to={page.link}
                  key={page.label}
                  size="small"
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    ":hover": { color: "white" },
                    "&.active": {
                      background: "#dbdbdb33",
                    },
                  }}
                >
                  {page.label}
                </Button>
              ) : null
            )}

            {auth && (
              <div className="ms-auto">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={profileUser}>Профиль</MenuItem>
                  <MenuItem onClick={settingsUser}>Настройки</MenuItem>
                  <MenuItem onClick={logoutUser}>Выйти</MenuItem>
                </Menu>
              </div>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
