import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    snackbar: {
        open: false,
        text: ''
    },
    loading: 'IDLE',
    error: null
}


export const uiSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setSnackBar: (state, action) => {
            state.snackbar = action.payload
        },
    },
    extraReducers: (builder) => {
    }
})

// Action creators are generated for each case reducer function
export const {setSnackBar} = uiSlice.actions

export default uiSlice.reducer
