import {Card, CardContent, Chip, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CustomToolbar from "../components/CustomToolbar";
import localeGrid from "../components/localeGrid";
import "../styles/operation.css";
import {getLuckyTransactions} from "../store/slices/transactionSlice";
import {unwrapResult} from "@reduxjs/toolkit";

function LuckyPayTransactions({}) {
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);


    function returnStatus(status) {
        switch (status) {
            case 'Unpaid':
                return <Chip label="Ожидает оплаты" color="info"/>;
            case 'Completed':
                return <Chip label="Оплата получена" color="success"/>;
            case 'CanceledByTimeout':
                return <Chip label="Отмена" color="error"/>;
            case 'CanceledByService':
                return <Chip label="Отмена" color="error"/>;
            default:
                return <Chip label="Неизвестно"/>;
        }
    }

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 300,
        },
        {
            field: "status",
            headerName: "Статус",
            width: 150,
            renderCell: (params) => returnStatus(params.value),
        },
        {
            field: "amount",
            headerName: "Сумма",
            width: 150,
        },

        {
            field: "holder_account",
            headerName: "Реквизиты",
            width: 300,
            renderCell: (params) => `${params.row.holder_account} (${params.row.method_name} ${params.row.holder_name})`,

        },
        {
            field: "created_at",
            headerName: "Дата создания",
            width: 150,
            valueFormatter: (params) => {
                return dayjs(params.value).format("DD.MM.YYYY HH:mm");
            },
        },
    ];

    const [tableHeight, setTableHeight] = React.useState(
        window.innerHeight - 200
    );
    const dispatch = useDispatch();
    const luckyTransactions = useSelector((state) => state.transaction.luckyTransactions);

    const resize = async () => {
        console.log(window.innerHeight);
        setTableHeight(window.innerHeight - 200);
    };



    React.useEffect(() => {
        dispatch(
            getLuckyTransactions({page: 1})
        )
            .then(unwrapResult)
            .then((result) => {
                console.log('result', result); // => 233
                setRows(result.data.data);
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });

        resize();
        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);


    function handlePageChange(page) {
        setPage(page + 1);
        dispatch(
            getLuckyTransactions({
                page: page + 1,
            })
        )
            .then(unwrapResult)
            .then((result) => {
                console.log(result); // => 233
                setRows(result.data.data);
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });
    }


    return (
            <DataGridPremium
                getRowClassName={(params) => `color-lucky-${params.row.status}`}
                rows={rows}
                paginationMode="server"
                pageSize={perPage}
                disableColumnFilter={true}
                rowsPerPageOptions={[10]}
                pagination
                onPageChange={handlePageChange}
                rowCount={luckyTransactions.meta?.total}
                columns={columns}
                components={{Toolbar: CustomToolbar}}
                componentsProps={{
                    toolbar: {
                        filename: "Транзакции CryptoSwapp",
                        showQuickFilter: true,
                        quickFilterProps: {debounceMs: 500},

                    },
                }}
                initialState={{
                    sorting: {
                        sortModel: [
                            {
                                field: "created_at",
                                sort: "desc",
                            },
                        ],
                    },
                }}
                localeText={localeGrid}
            />
    );
}

export default LuckyPayTransactions;
