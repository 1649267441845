import React, {useCallback, useState} from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider, Fade, FormControl,
    FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import '../styles/operation.css'; // Tell webpack that Button.js uses these styles
import {useForm, Controller} from "react-hook-form";
import {login} from "../store/slices/authSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import CustomToolbar from "../components/CustomToolbar";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from "../layouts/Header";
import {createOperation, deleteOperations, editOperation, getOperations} from "../store/slices/operationSlice";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {DataGridPremium, GridActionsCellItem} from "@mui/x-data-grid-premium";
import localeGrid from "../components/localeGrid";
import {createNotification, deleteNotifications, getNotifications} from "../store/slices/notificationSlice";
import InfoIcon from '@mui/icons-material/Info';
import {deleteUsers as delUsers} from "../store/slices/userSlice";

var _ = require('lodash');


function Notifications() {

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 75
        },

        {
            field: 'title',
            headerName: 'Заголовок',
            width: 100
        },
        {
            field: 'description',
            headerName: 'Сообщение',
            width: 300
        },
        {
            field: 'severity',
            headerName: 'Тип',
            width: 150,
            valueFormatter: (params) => {
                switch (params.value) {
                    case 'error':
                        return 'Ошибка';
                    case 'warning':
                        return 'Предупреждение';
                    case 'info':
                        return 'Информация';
                    case 'success':
                        return 'Успешно';
                    default:
                        return 'Неизвестно';
                }
            }
        },

        {
            field: 'created_at',
            headerName: "Дата создания",
            width: 150,
            valueFormatter: (params) => {
                return dayjs(params.value).format('DD.MM.YYYY HH:mm')
            }
        },

        {
            field: "actions",
            type: "actions",
            headerName: 'Действия',
            width: 250,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Удалить"
                    onClick={deleteNotification(params.id)}
                />,
            ]
        }
    ];


    const [tableHeight, setTableHeight] = React.useState(window.innerHeight - 200);
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notification.notifications)
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deletedNotifications, setDeletedNotifications] = useState([]);


    const resize = async () => {
        console.log(window.innerHeight)
        setTableHeight(window.innerHeight - 200)
    }

    React.useEffect(() => {
        dispatch(getNotifications())
        resize()
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [])


    function dialogDeleteUsers() {
        dispatch(deleteNotifications(deletedNotifications))
        setOpenDeleteDialog(false)
    }


    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues
    } = useForm({
        defaultValues: {
            title: '',
            description: '',
            severity: 'info',
        }
    });

    const OnCreateNotification = () => {
        reset()
        setOpenCreateDialog(true)
    };


    const onCreateNotificationFinish = data => {
        console.log(data)
        dispatch(createNotification(data))
        setOpenCreateDialog(false)
    }


    const deleteSelected = () => {
        const array = selectionModel.map((id) => Number(id))
        // dispatch(delUsers(array))
        setOpenDeleteDialog(true)
        setDeletedNotifications(array)
    };


    const deleteNotification = useCallback(
        (id) => () => {
            let array = []
            array.push(Number(id))
            setDeletedNotifications(array)
            setOpenDeleteDialog(true)
        },
        []
    );

    function dialogDeleteNotifications() {
        dispatch(deleteNotifications(deletedNotifications))
        setOpenDeleteDialog(false)
    }

    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">
                    <Typography color="text.primary">
                        Уведомления
                    </Typography>

                    <div className="row">
                        <div className="col-12 justify-content-end text-end">

                            <Button variant="contained" className="ms-3" onClick={OnCreateNotification}>
                                Создать уведомление
                            </Button>

                            <Button variant="contained" className="ms-3" onClick={deleteSelected}
                                    disabled={selectionModel.length == 0}>
                                Удалить выбранные
                            </Button>


                        </div>

                    </div>

                    <div className="row">
                        <div className="col-12 mt-3">
                            <div style={{height: tableHeight, width: '100%'}}>
                                <DataGridPremium
                                    rowHeight={80}
                                    getRowClassName={(params) => `color-${params.row.status}`}
                                    rows={notifications}
                                    columns={columns}
                                    components={{Toolbar: CustomToolbar}}
                                    componentsProps={{toolbar: {filename: 'Уведомления'}}}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    initialState={{
                                        sorting: {
                                            sortModel: [
                                                {
                                                    field: "id",
                                                    sort: "desc"
                                                }
                                            ]
                                        },
                                        pinnedColumns: {right: ['actions']}
                                    }}
                                    onSelectionModelChange={setSelectionModel}
                                    selectionModel={selectionModel} //
                                    localeText={localeGrid}

                                />
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>

            <Dialog
                open={openCreateDialog}
                maxWidth="sm"
                fullWidth={true}
                onClose={() => setOpenCreateDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Новое уведомление
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <form>
                                <div className="1">
                                    <Controller
                                        name="title"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                className="w-100"
                                                type="text"
                                                label="Заголовок"
                                                {...field}
                                            />}
                                    />
                                </div>


                                <div className="mt-3">
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                multiline
                                                variant="outlined"
                                                rows={4}
                                                className="w-100"
                                                label="Описание"
                                                {...field}
                                            />}
                                    />
                                </div>

                                <div className="mt-3">
                                    <FormControl style={{minWidth: '100%'}}>
                                        <InputLabel id="type-label">Тип уведомления</InputLabel>
                                        <Controller
                                            name="severity"
                                            id="severity"
                                            defaultValue='info'
                                            control={control}
                                            render={({field}) => (
                                                <Select labelId="level-label" {...field}>
                                                    <MenuItem value="error">
                                                        <InfoIcon color="error" fontSize="small" sx={{
                                                            marginRight: '5px'
                                                        }}/>
                                                        Ошибка
                                                    </MenuItem>
                                                    <MenuItem value="warning">
                                                        <InfoIcon color="warning" fontSize="small" sx={{
                                                            marginRight: '5px'
                                                        }}/>
                                                        Предупреждение</MenuItem>
                                                    <MenuItem value="info">
                                                        <InfoIcon color="info" fontSize="small" sx={{
                                                            marginRight: '5px'
                                                        }}/>
                                                        Информация</MenuItem>
                                                    <MenuItem value="success">
                                                        <InfoIcon color="success" fontSize="small" sx={{
                                                            marginRight: '5px'
                                                        }}/>
                                                        Успех</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </div>

                            </form>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCreateDialog(false)}>Отмена</Button>
                    <Button onClick={handleSubmit(onCreateNotificationFinish)}>
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Подтвердить действие
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Удалить выбранные уведомления?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>Отмена</Button>
                    <Button onClick={() => dialogDeleteNotifications()} autoFocus>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>


        </div>
    );
}

export default Notifications;
