import {configureStore} from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import authReducer from './slices/authSlice'
import settingsReducer from './slices/settingsSlice'
import operationReducer from './slices/operationSlice'
import transactionReducer from './slices/transactionSlice'
import uiReducer from './slices/uiSlice'
import requestReducer from './slices/requestSlice'
import merchanReducer from './slices/merchantSlice'
import notificationReducer from './slices/notificationSlice'
import logReducer from './slices/logSlice'

export const store = configureStore({
    reducer: {
        user: userReducer,
        auth: authReducer,
        settings: settingsReducer,
        operation: operationReducer,
        transaction: transactionReducer,
        request: requestReducer,
        merchant: merchanReducer,
        ui: uiReducer,
        notification: notificationReducer,
        log: logReducer
    },
})
