import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
    DataGridPremium,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridActionsCellItem,
    gridDetailPanelExpandedRowsContentCacheSelector,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid-premium";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useCallback, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import CustomToolbar from "../components/CustomToolbar";
import localeGrid from "../components/localeGrid";
import {createNotification} from "../store/slices/notificationSlice";
import {
    changeApproveAutowithdrawalAddress,
    crUser,
    deleteUsers as delUsers,
    editUser as edUser,
    getUsers,
    resetPass,
} from "../store/slices/userSlice";
import {useNavigate} from "react-router-dom";

const dayjs = require("dayjs");

function Users() {
    const dispatch = useDispatch();
    // const {data, pending, error, posts, test} = useSelector((state) => state.kanyeQuote);
    const users = useSelector((state) => state.user.users);
    const role = useSelector((state) => state.auth.user).role;
    const navigate = useNavigate();

    const [tableHeight, setTableHeight] = useState(100);
    const [selectionModel, setSelectionModel] = useState([]);
    const [deletedUsers, setDeletedUsers] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [openBlockDialog, setOpenBlockDialog] = useState(false);
    const [blockedUser, setBlockedUser] = useState();

    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [passwordDialog, setPasswordDialog] = useState(false);
    const [balanceDialog, setBalanceDialog] = useState(false);
    const [addBalanceValue, setAddBalanceValue] = useState(0);
    const [openCreateNotificationDialog, setOpenCreateNotificationDialog] =
        React.useState(false);

    const merchants = useSelector((state) => state.auth.merchants);

    const {control, register, handleSubmit, setValue, watch, reset} = useForm({
        defaultValues: {
            id: 0,
            created_at: null,
            login: "",
            balance: 0,
            updated_at: null,
            coefficient: 0,
            referral_percent: 1,
            withdraw_password: "",
            referral_code: "",
        },
    });

    const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const {
        control: controlNotification,
        handleSubmit: handleSubmitNotification,
        setValue: setValueNotification,
        reset: resetNotification,
    } = useForm({
        defaultValues: {
            title: "",
            description: "",
            severity: "info",
            user_id: null,
        },
    });

    const watchEditUser = watch(); // you can supply default value as second argument

    const {
        control: controlCreate,
        register: registerCreate,
        handleSubmit: handleSubmitCreate,
        reset: resetCreate,
    } = useForm({
        defaultValues: {
            id: null,
            login: "",
            password: "",
            balance: 0,
            withdraw_password: "",
            referral_code: "",
            referral_percent: 1,
            coefficient: 0,
        },
    });

    const {
        control: controlResetPassword,
        handleSubmit: handleSubmitResetPassword,
        setValue: setValueResetPassword,
    } = useForm({
        defaultValues: {
            password: "",
            id: 0,
        },
    });

    const resize = async () => {
        console.log(window.innerHeight);
        setTableHeight(window.innerHeight - 200);
    };

    useEffect(() => {
        dispatch(getUsers());
        resize();
        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    const deleteUser = useCallback(
        (id) => () => {
            let array = [];
            array.push(Number(id));
            setDeletedUsers(array);
            setOpenDeleteDialog(true);
            // dispatch(delUsers(array))
        },
        []
    );

    const deleteSelected = () => {
        const array = selectionModel.map((id) => Number(id));
        // dispatch(delUsers(array))
        setOpenDeleteDialog(true);
        setDeletedUsers(array);
    };

    const addUser = () => {
        resetCreate();
        setOpenCreateDialog(true);
    };

    const editUser = useCallback(
        (row) => () => {
            console.log(row);
            reset();

            for (const property in row) {
                setValue(property, row[property]);
            }

            setOpenEditDialog(true);
        },
        []
    );

    const resetPassword = useCallback(
        (row) => () => {
            console.log(row);
            setValueResetPassword("password", "");
            setValueResetPassword("id", row.id);
            setPasswordDialog(true);
        },
        []
    );

    const {
        control: controlAddBalance,
        register: registerAddBalance,
        handleSubmit: handleAddBalance,
        setValue: setValueAddBalance,
        watch: watchAddBalance,
        reset: resetAddBalance,
    } = useForm({
        defaultValues: {
            id: 0,
            balance: 0,
            balance_fiat: 0,
            type: merchants[0].type,
            coefficient: 0,
            description_admin: "",
        },
    });

    const addBalanceForm = watchAddBalance();
    // let addBalanceForm = watchAddBalance(); // you can supply default value as second argument
    //
    useEffect(() => {
        for (let i = 0; i < merchants.length; i++) {
            let merchant = merchants[i];
            if (merchant.type === addBalanceForm.type) {
                let totalCommission =
                    parseFloat(merchant.commission) +
                    parseFloat(addBalanceForm.coefficient);
                let commissionPercent = Number(totalCommission) / 100;
                let rateCommission = merchant.rate + merchant.rate * commissionPercent;
                let result = 0;
                if (rateCommission > 0) {
                    let rateAdd = rateCommission.toFixed(2);
                    result = (addBalanceForm.balance_fiat / rateAdd).toFixed(2);
                }
                console.log("result", result);
                setAddBalanceValue(result);
                // setValueAddBalance('balance', result)
            }
        }
    }, [addBalanceForm]);

    const addBalance = useCallback(
        (row) => () => {
            console.log(row);
            resetAddBalance();
            setValueAddBalance("id", row.id);
            setValueAddBalance("balance", row.balance);
            setValueAddBalance("coefficient", row.coefficient);
            setBalanceDialog(true);
        },
        []
    );


    const blockUnblockUser = useCallback(
        (row) => () => {
            console.log(row);
            setBlockedUser(row);
            setOpenBlockDialog(true);
        },
        []
    );

    const addNotification = useCallback(
        (row) => () => {
            console.log(row);
            resetNotification();
            setValueNotification("user_id", row.id);
            setOpenCreateNotificationDialog(true);
        },
        []
    );

    const onEditUser = (data) => {
        console.log(data);
        data.balance = Number(data.balance);
        dispatch(edUser(data))
            .then(unwrapResult)
            .then((result) => {
                dispatch(getUsers());

                setOpenEditDialog(false);

                // navigate.push('/dashboard')
                // navigate("/dashboard", {replace: true});
            })
            .catch((error) => {
                setOpenEditDialog(false);
            });
    };

    const onCreateUser = (data) => {
        console.log(data);
        dispatch(crUser(data))
            .then(unwrapResult)
            .then((result) => {
                dispatch(getUsers());

                setOpenCreateDialog(false);

                // navigate.push('/dashboard')
                // navigate("/dashboard", {replace: true});
            })
            .catch((error) => {
                setOpenCreateDialog(false);
            });
    };

    const onResetPassword = (data) => {
        console.log(data);
        dispatch(resetPass(data));
        setPasswordDialog(false);
    };

    const onAddBalance = (data) => {
        let test = "test";
        console.log(data);
        let totalBalance = parseFloat(data.balance) + parseFloat(addBalanceValue);
        console.log("totalBalance", totalBalance);
        dispatch(
            edUser({
                id: data.id,
                balance: parseFloat(totalBalance.toFixed(2)),
            })
        )
            .then(unwrapResult)
            .then((result) => {
                dispatch(getUsers());
                console.log("result", result); // => 233
                let data2 = {
                    title: "Пополние баланса",
                    description: `Баланс пополнен на $${parseFloat(
                        addBalanceValue
                    ).toFixed(2)} \n${data.description_admin}`,
                    severity: "info",
                    user_id: data.id,
                };
                dispatch(createNotification(data2));
            })
            .catch((error) => {
                console.error("error", error); // if there is an error
            });
        setBalanceDialog(false);
    };

    function dialogDeleteUsers() {
        dispatch(delUsers(deletedUsers))
            .then(unwrapResult)
            .then((result) => {
                dispatch(getUsers());

                setOpenDeleteDialog(false);

                // navigate.push('/dashboard')
                // navigate("/dashboard", {replace: true});
            })
            .catch((error) => {
                setOpenDeleteDialog(false);
            });
    }

    function dialogBlockUser() {
        const updatedUser = {...blockedUser, is_blocked: blockedUser.is_blocked === 1 ? 0 : 1};
        console.log(updatedUser);


        dispatch(edUser(updatedUser))
            .then(unwrapResult)
            .then((result) => {
                dispatch(getUsers());
                setOpenBlockDialog(false);
            })
            .catch((error) => {
                setOpenBlockDialog(false);
            });

        // dispatch(delUsers(deletedUsers))
        //     .then(unwrapResult)
        //     .then((result) => {
        //         dispatch(getUsers());
        //
        //         setOpenDeleteDialog(false);
        //
        //         // navigate.push('/dashboard')
        //         // navigate("/dashboard", {replace: true});
        //     })
        //     .catch((error) => {
        //         setOpenDeleteDialog(false);
        //     });
    }

    function dialogEditUser() {
        // dispatch(delUsers(deletedUsers))
        setOpenDeleteDialog(false);
    }

    const closeDeleteDialog = (event, reason) => {
        setSelectionModel([]);
        setOpenDeleteDialog(false);
    };

    const closeEditDialog = (event, reason) => {
        setOpenEditDialog(false);
    };

    function userRate(merchant, rate, commission, coefficient) {
        let totalCommission = parseFloat(commission) + parseFloat(coefficient);
        let commissionPercent = Number(totalCommission) / 100;
        let rateCommission = rate + rate * commissionPercent;
        return rateCommission.toFixed(2);
    }

    function returnUserRate(coefficient) {
        let arrayValues = [];
        for (let i = 0; i < merchants.length; i++) {
            let merchant = merchants[i];
            let totalCommission =
                parseFloat(merchant.commission) + parseFloat(coefficient);
            let commissionPercent = Number(totalCommission) / 100;
            let rateCommission = merchant.rate + merchant.rate * commissionPercent;
            let result = 0;
            if (rateCommission > 0) {
                result = rateCommission.toFixed(2);
            }
            arrayValues.push({
                id: merchant.id,
                value: `${merchant.type} - ${result} ₽`,
            });
        }
        return (
            <div>
                {arrayValues.map((value) => (
                    <div key={value.id}>{value.value}</div>
                ))}
            </div>
        );
    }

    const onCreateNotificationFinish = (data) => {
        console.log(data);
        dispatch(createNotification(data));
        setOpenCreateNotificationDialog(false);
    };

    function CustomDetailPanelToggle(props) {
        const {id, value: isExpanded, count_autowithdrawal_addresses} = props;
        const apiRef = useGridApiContext();

        // To avoid calling ´getDetailPanelContent` all the time, the following selector
        // gives an object with the detail panel content for each row id.
        const contentCache = useGridSelector(
            apiRef,
            gridDetailPanelExpandedRowsContentCacheSelector
        );

        // If the value is not a valid React element, it means that the row has no detail panel.
        const hasDetail = React.isValidElement(contentCache[id]);

        return (
            <div className="d-flex align-items-center">
                <Typography
                    variant="caption"
                    display="block"
                    color={count_autowithdrawal_addresses > 0 ? "red" : "initial"}
                    gutterBottom
                >
                    {count_autowithdrawal_addresses} Неподтвержденных адресов
                </Typography>

                <IconButton
                    size="small"
                    className="mb-1"
                    tabIndex={-1}
                    disabled={!hasDetail}
                    aria-label={isExpanded ? "Close" : "Open"}
                >
                    <ExpandMoreIcon
                        sx={{
                            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                            transition: (theme) =>
                                theme.transitions.create("transform", {
                                    duration: theme.transitions.duration.shortest,
                                }),
                        }}
                        fontSize="inherit"
                    />
                </IconButton>
            </div>
        );
    }

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 60,
        },
        {
            field: "login",
            headerName: "Логин",
            width: 120,
        },
        {
            field: "coefficient",
            headerName: "Коэфицент",
            width: 100,
        },
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            headerName: "Адреса для автовыода",
            width: 240,
            renderCell: (params) => (
                <CustomDetailPanelToggle
                    id={params.id}
                    value={params.value}
                    count_autowithdrawal_addresses={
                        params.row.autowithdrawal_addresses.filter(
                            (item) => item.is_approved === 0
                        ).length
                    }
                />
            ),
        },

        {
            field: "referral_percent",
            headerName: "Процент рефералов",
            width: 150,
        },

        {
            field: "coefficient_merchants",
            headerName: "Коэфицент мерчантов",
            width: 180,
            renderCell: (params) => returnUserRate(params.row.coefficient),
        },

        {
            field: "balance",
            headerName: "Баланс",
            width: 100,
            // valueFormatter: (params) => {
            //   return Number(params.value).toFixed(2);
            // },

            valueFormatter: ({value}) => {
                if (!value) {
                    return value;
                }
                return currencyFormatter.format(value);
            },
        },
        {
            field: "referral_code",
            headerName: "Реферальный код",
            width: 180,
            renderCell: (params) =>
                params.row.referral_code && (
                    <div className="d-flex align-items-center">
                        <div>{params.row.referral_code}</div>
                        <Button
                            onClick={() => {
                                navigator.clipboard.writeText(params.row.referral_code);
                            }}
                        >
                            <ContentCopyIcon fontSize="small"/>
                        </Button>
                    </div>
                ),
        },
        {
            field: "own_referral_code",
            headerName: "Свой реферальный код",
            width: 180,
            renderCell: (params) =>
                params.row.own_referral_code && (
                    <div className="d-flex align-items-center">
                        <div>{params.row.own_referral_code}</div>
                        <Button
                            onClick={() => {
                                navigator.clipboard.writeText(params.row.own_referral_code);
                            }}
                        >
                            <ContentCopyIcon fontSize="small"/>
                        </Button>
                    </div>
                ),
        },

        {
            field: "created_at",
            headerName: "Дата регистрации",
            width: 150,
            valueFormatter: (params) => {
                return dayjs(params.value).format("DD.MM.YYYY HH:mm");
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Действия",
            width: 370,
            getActions: (params) => returnArrayActions(params),
        },
    ];

    function returnArrayActions(params) {
        if (role == "admin") {
            return [
                <GridActionsCellItem
                    icon={<RemoveRedEyeIcon/>}
                    label="Посмотреть"
                    onClick={() => navigate(`/user/${params.id}`)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Удалить"
                    onClick={deleteUser(params.id)}
                />,
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Редактировать"
                    onClick={editUser(params.row)}
                />,
                <GridActionsCellItem
                    icon={<NotificationsNoneIcon/>}
                    label="Уведомление"
                    onClick={addNotification(params.row)}
                />,
                <GridActionsCellItem

                    icon={
                        params.row.is_blocked == 0 ?
                            <BlockIcon sx={{color: 'red'}}/>
                            :
                            <CheckCircleOutlineIcon sx={{color: 'green'}}/>
                    }
                    label="Блокировка"
                    onClick={blockUnblockUser(params.row)}
                />,
                <Button
                    variant="contained"
                    size="tiny"
                    sx={{
                        height: "35px",
                        fontSize: "10px",
                    }}
                    onClick={resetPassword(params.row)}
                >
                    Сбросить пароль
                </Button>,
                <Button
                    variant="contained"
                    size="small"
                    sx={{
                        height: "35px",
                        fontSize: "10px",
                    }}
                    onClick={addBalance(params.row)}
                >
                    Пополнить баланс
                </Button>,
            ];
        } else {
            return [
                <GridActionsCellItem
                    icon={<RemoveRedEyeIcon/>}
                    label="Посмотреть"
                    onClick={() => navigate(`/user/${params.id}`)}
                />,
                <GridActionsCellItem
                    icon={<NotificationsNoneIcon/>}
                    label="Уведомление"
                    onClick={addNotification(params.row)}
                />,
                <Button
                    variant="contained"
                    size="small"
                    onClick={addBalance(params.row)}
                >
                    Пополнить баланс
                </Button>,
            ];
        }
    }

    function DetailPanelContent({row: rowProp}) {
        const [tableData, setTableData] = useState([]);

        function handleConfirmChange(clickedRow) {
            console.log(clickedRow);
            const updatedData = tableData.map((x) => {
                if (x.id === clickedRow.id) {
                    let updatedObject = {
                        ...x,
                        is_approved: !clickedRow.is_approved,
                    };

                    dispatch(changeApproveAutowithdrawalAddress(updatedObject)).finally(
                        () => {
                            dispatch(getUsers());
                        }
                    );
                    return updatedObject;
                }
                return x;
            });
            setTableData(updatedData);
        }

        useEffect(() => {
            const processedData = rowProp.autowithdrawal_addresses.map((item) => ({
                ...item,
                is_approved: Boolean(item.is_approved),
            }));

            setTableData(processedData);
        }, [rowProp]);

        return (
            <Stack
                sx={{height: "100%", boxSizing: "border-box"}}
                direction="column"
            >
                <Paper sx={{flex: 1, width: "90%", p: 1}}>
                    <Stack direction="column" spacing={1} sx={{height: 1}}>
                        <Typography variant="h6">Адреса для автовывода</Typography>

                        <DataGridPremium
                            localeText={localeGrid}
                            autoHeight
                            // density="compact"
                            columns={[
                                {field: "address", headerName: "Адрес", width: 500},
                                {
                                    field: "is_approved",
                                    width: 150,
                                    headerName: "Подтвержден",
                                    renderCell: (params) => (
                                        <Checkbox
                                            checked={params.value}
                                            onChange={() => handleConfirmChange(params.row)}
                                        />
                                    ),
                                },
                            ]}
                            initialState={{
                                // sorting: {
                                //   sortModel: [{ field: "is_approved", sort: "asc" }],
                                // },
                            }}
                            rows={tableData}
                            sx={{flex: 1}}
                            hideFooter
                        />
                    </Stack>
                </Paper>
            </Stack>
        );
    }

    const getDetailPanelHeight = React.useCallback(() => 220, []);

    const getDetailPanelContent = React.useCallback(
        ({row}) => <DetailPanelContent row={row}/>,
        []
    );

    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">
                    <Typography color="text.primary">Пользователи</Typography>

                    {role == "admin" && (
                        <div className="row">
                            <div className="col-12 justify-content-between d-flex align-items-center">
                                <div>
                                    Общий баланс:{" "}
                                    <b>
                                        {" "}
                                        {currencyFormatter.format(
                                            users.reduce((sum, user) => sum + user.balance, 0)
                                        )}
                                    </b>
                                </div>

                                <div>
                                    <Button variant="contained" className="" onClick={addUser}>
                                        Добавить пользователя
                                    </Button>

                                    <Button
                                        variant="contained"
                                        className="ms-3"
                                        onClick={deleteSelected}
                                        disabled={selectionModel.length == 0}
                                    >
                                        Удалить выбранные
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12 mt-3">
                            <div style={{height: tableHeight, width: "100%"}}>
                                <DataGridPremium
                                    rows={users}
                                    columns={columns}
                                    components={{Toolbar: CustomToolbar}}
                                    componentsProps={{toolbar: {filename: "Пользователи"}}}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    experimentalFeatures={{aggregation: true}}
                                    initialState={{
                                        aggregation: {
                                            model: {
                                                balance: "sum",
                                            },
                                        },
                                        sorting: {
                                            sortModel: [
                                                {
                                                    field: "id",
                                                    sort: "desc",
                                                },
                                            ],
                                        },
                                        pinnedColumns: {right: ["actions"]},
                                    }}
                                    onSelectionModelChange={setSelectionModel}
                                    selectionModel={selectionModel} //
                                    localeText={localeGrid}
                                    rowThreshold={0}
                                    getDetailPanelHeight={getDetailPanelHeight}
                                    getDetailPanelContent={getDetailPanelContent}
                                />
                            </div>
                        </div>
                    </div>
                    <Dialog
                        open={openDeleteDialog}
                        onClose={closeDeleteDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Подтвердить действие
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Удалить выбранных пользователей?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDeleteDialog}>Отмена</Button>
                            <Button onClick={() => dialogDeleteUsers()} autoFocus>
                                Да
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Dialog
                        open={openBlockDialog}
                        onClose={() => setOpenBlockDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Подтвердить действие
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {blockedUser?.is_blocked == 0 ?
                                    (<>
                                        Заблокировать пользователя ?</>)
                                    :
                                    (<>Разблокировать пользователя?</>)
                                }

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenBlockDialog(false)}>Отмена</Button>
                            <Button onClick={() => dialogBlockUser()} autoFocus>
                                Да
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openEditDialog}
                        onClose={closeEditDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Редактировать пользователя
                        </DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={handleSubmit(onEditUser)}>
                                        <div className="mt-1">
                                            <Controller
                                                name="login"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label="Логин"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <Controller
                                                name="balance"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label="Баланс"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <Controller
                                                name="coefficient"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label="Коэфицент (%)"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="withdraw_password"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        // type="password"
                                                        label="Пароль для вывода"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="referral_code"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label="Реферальный код (пригласительный)"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="referral_percent"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label="Процент реферального вознаграждения (%)"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <Typography variant="subtitle2" className="mt-1">
                                            Курс пользователя:
                                        </Typography>
                                        <div className="mt-1">
                                            {returnUserRate(watchEditUser.coefficient)}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeEditDialog}>Отмена</Button>
                            <Button onClick={handleSubmit(onEditUser)} autoFocus>
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openCreateDialog}
                        onClose={() => setOpenCreateDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Добавить пользователя
                        </DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12">
                                    <form>
                                        <div className="mt-1">
                                            <Controller
                                                name="login"
                                                control={controlCreate}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label="Логин"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <Controller
                                                name="balance"
                                                control={controlCreate}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label="Баланс"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="password"
                                                control={controlCreate}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label="Пароль"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="withdraw_password"
                                                control={controlCreate}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label="Пароль для вывода"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="referral_code"
                                                control={controlCreate}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label="Реферальный код (пригласительный)"
                                                        {...field}
                                                    />
                                                )}
                                            />

                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom
                                            >
                                                Личный реферальный код пользователя сгенерируется
                                                автоматически
                                            </Typography>
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="referral_percent"
                                                control={controlCreate}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label="Процент реферального вознаграждения (%)"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenCreateDialog(false)}>Отмена</Button>
                            <Button onClick={handleSubmitCreate(onCreateUser)} autoFocus>
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={passwordDialog}
                        onClose={() => setPasswordDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={true}
                        maxWidth="xs"
                    >
                        <DialogTitle id="alert-dialog-title">Сбросить пароль</DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12">
                                    <form>
                                        <div className="mt-1">
                                            <Controller
                                                name="password"
                                                control={controlResetPassword}
                                                render={({field}) => (
                                                    <TextField
                                                        label="Новый пароль"
                                                        className="w-100"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setPasswordDialog(false)}>Отмена</Button>
                            <Button
                                onClick={handleSubmitResetPassword(onResetPassword)}
                                autoFocus
                            >
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={balanceDialog}
                        onClose={() => setBalanceDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={true}
                        maxWidth="xs"
                    >
                        <DialogTitle>Пополнить баланс</DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12">
                                    <form>
                                        <div className="mt-2">
                                            <Controller
                                                name="balance_fiat"
                                                control={controlAddBalance}
                                                render={({field}) => (
                                                    <TextField
                                                        type={"number"}
                                                        label="Сумма в RUB"
                                                        className="w-100"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <FormControl style={{minWidth: "100%"}}>
                                                <InputLabel id="type-label">Тип мерча</InputLabel>
                                                <Controller
                                                    name="type"
                                                    id="type"
                                                    defaultValue={0}
                                                    control={controlAddBalance}
                                                    render={({field}) => (
                                                        <Select labelId="level-label" {...field}>
                                                            {merchants.map((merchant) => (
                                                                <MenuItem
                                                                    key={merchant.id}
                                                                    value={merchant.type}
                                                                >
                                                                    {merchant.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                        <Typography variant="subtitle1" className="mt-1">
                                            Пополняемый баланс - <b>${addBalanceValue}</b>
                                        </Typography>

                                        <div className="mt-3">
                                            <Controller
                                                name="description_admin"
                                                control={controlAddBalance}
                                                render={({field}) => (
                                                    <TextField
                                                        type={"text"}
                                                        label="Комментарий для уведомления"
                                                        className="w-100"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setBalanceDialog(false)}>Отмена</Button>
                            <Button onClick={handleAddBalance(onAddBalance)} autoFocus>
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openCreateNotificationDialog}
                        maxWidth="sm"
                        fullWidth={true}
                        onClose={() => setOpenCreateNotificationDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Уведомление пользователю
                        </DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <form>
                                        <div className="1">
                                            <Controller
                                                name="title"
                                                control={controlNotification}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        type="text"
                                                        label="Заголовок"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="description"
                                                control={controlNotification}
                                                render={({field}) => (
                                                    <TextField
                                                        multiline
                                                        variant="outlined"
                                                        rows={4}
                                                        className="w-100"
                                                        label="Описание"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <FormControl style={{minWidth: "100%"}}>
                                                <InputLabel id="type-label">Тип уведомления</InputLabel>
                                                <Controller
                                                    name="severity"
                                                    id="severity"
                                                    defaultValue="info"
                                                    control={controlNotification}
                                                    render={({field}) => (
                                                        <Select labelId="level-label" {...field}>
                                                            <MenuItem value="error">
                                                                <InfoIcon
                                                                    color="error"
                                                                    fontSize="small"
                                                                    sx={{
                                                                        marginRight: "5px",
                                                                    }}
                                                                />
                                                                Ошибка
                                                            </MenuItem>
                                                            <MenuItem value="warning">
                                                                <InfoIcon
                                                                    color="warning"
                                                                    fontSize="small"
                                                                    sx={{
                                                                        marginRight: "5px",
                                                                    }}
                                                                />
                                                                Предупреждение
                                                            </MenuItem>
                                                            <MenuItem value="info">
                                                                <InfoIcon
                                                                    color="info"
                                                                    fontSize="small"
                                                                    sx={{
                                                                        marginRight: "5px",
                                                                    }}
                                                                />
                                                                Информация
                                                            </MenuItem>
                                                            <MenuItem value="success">
                                                                <InfoIcon
                                                                    color="success"
                                                                    fontSize="small"
                                                                    sx={{
                                                                        marginRight: "5px",
                                                                    }}
                                                                />
                                                                Успех
                                                            </MenuItem>
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenCreateNotificationDialog(false)}>
                                Отмена
                            </Button>
                            <Button
                                onClick={handleSubmitNotification(onCreateNotificationFinish)}
                            >
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </CardContent>
            </Card>
        </div>
    );
}

export default Users;
