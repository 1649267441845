import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  users: [],
  admins: [],
  loading: "IDLE",
  error: null,
};

export const getUsers = createAsyncThunk("user/get-users", async (data) => {
  let response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api-admin/get-users`
  );

  console.log(response.data);
  return response.data;
});

export const getAdmins = createAsyncThunk("user/get-admins", async (data) => {
  let response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api-admin/get-admins`
  );

  console.log(response.data);
  return response.data;
});

export const deleteUsers = createAsyncThunk(
  "user/delete-users",
  async (ids) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api-admin/delete-users`,
      ids
    );
    return response.data.data;
  }
);

export const editUser = createAsyncThunk("user/edit-user", async (data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api-admin/edit-user`,
    data
  );
  return response.data.data;
});

export const crUser = createAsyncThunk("user/edit-user", async (data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api-admin/create-user`,
    data
  );
  return response.data.data;
});

export const resetPass = createAsyncThunk("user/reset-pass", async (data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api-admin/reset-password`,
    data
  );
  return response.data.data;
});

export const changeApproveAutowithdrawalAddress = createAsyncThunk(
  "user/change-approve-autowithdrawal-address",
  async (data) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api-admin/change-approve-autowithdrawal-address`,
      data
    );
    return response.data.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // filterUsers: (state, action) => {
    //     state.users = state.users.filter(user => {
    //         user.role == 'user'
    //     })
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(getAdmins.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(deleteUsers.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(editUser.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })

      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = "idle";
        console.log();
        state.users = action.payload.data;
        state.error = null;
      })
      .addCase(getAdmins.fulfilled, (state, action) => {
        state.loading = "idle";
        console.log();
        state.admins = action.payload.data;
        state.error = null;
      })

      .addCase(deleteUsers.fulfilled, (state, action) => {
        state.loading = "idle";
        console.log(action.payload);
        // state.users = action.payload
        state.error = null;
      })

      .addCase(editUser.fulfilled, (state, action) => {
        state.loading = "idle";
        console.log(action.payload);
        // state.users = action.payload
        state.error = null;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.users = [];
        state.loading = "failed";
        state.error = action.payload.code;
      })
      .addCase(getAdmins.rejected, (state, action) => {
        state.admins = [];
        state.loading = "failed";
        state.error = action.payload.code;
      })
      .addCase(deleteUsers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.code;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.code;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = userSlice.actions;

export default userSlice.reducer;
