import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { editSettings } from "src/store/slices/settingsSlice";

function AutowithdrawalSetting() {
  const settings = useSelector((state) => state.settings.settings);
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(false);

  const { register, control, handleSubmit, setValue, getValues, watch } =
    useForm({
      defaultValues: {
        id: null,
        name: "autowithdrawal",
        is_activate: false,
        max_limit: 10000,
        min_limit: 1000,
        commission: 2,
      },
    });
  const watchAutowithdrawal = watch(); // you can supply default value as second argument

  React.useEffect(() => {
    let data = {
      id: watchAutowithdrawal.id,
      name: watchAutowithdrawal.name,
      is_activate: watchAutowithdrawal.is_activate,
      value: JSON.stringify({
        max_limit: watchAutowithdrawal.max_limit,
        min_limit: watchAutowithdrawal.min_limit,
        commission: watchAutowithdrawal.commission,
      }),
    };
    dispatch(editSettings(data));
  }, [watchAutowithdrawal, active]);

  React.useEffect(() => {
    for (let i = 0; i < settings.length; i++) {
      if (settings[i].name == "autowithdrawal") {
        if (settings[i].is_activate == 1) {
          setValue("is_activate", true);
        } else {
          setValue("is_activate", false);
        }
        let value = JSON.parse(settings[i].value);
        setValue("min_limit", Number(value.min_limit));
        setValue("max_limit", Number(value.max_limit));
        setValue("commission", Number(value.commission));

      }
    }
  }, []);

  const handleChange = (event) => {
    setActive(event.target.checked);
  };

  return (
    <div>
      <form className="mt-1">
        <div>
          <div className="mt">
            <FormControlLabel
              control={
                <Controller
                  name="is_activate"
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label="Включить автовывод"
            />
          </div>
        </div>
        <div className="d-flex justify-content-beetwen gap-3 mt-3">
          <Controller
            name="min_limit"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                sx={{ width: "100%" }}
                {...field}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                label={`Минимальный лимит`}
              />
            )}
          />

          <Controller
            name="max_limit"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                sx={{ width: "100%" }}
                {...field}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                label={`Максимальный лимит`}
              />
            )}
          />
        </div>

        <div className="mt-3">
          <Controller
            name="commission"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                sx={{ width: "100%" }}
                {...field}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                label={`Комиссия`}
              />
            )}
          />
        </div>
      </form>
    </div>
  );
}

export default AutowithdrawalSetting;
