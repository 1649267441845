import {
    Button,
    Card,
    CardContent,
    CircularProgress, Stack, Switch,
    Typography,
} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import AutowithdrawalSetting from "../components/AutowithdrawalSetting";
import BalanceBitcoin from "../components/BalanceBitcoin";
import BotSetting from "../components/BotSetting";
import MaxLimitBitcoin from "../components/MaxLimitBitcoin";
import MerchantSetting from "../components/MerchantSetting";
import MinLimitBitcoin from "../components/MinLimitBitcoin";
import PasswordBitcoin from "../components/PasswordBitcoin";
import TechnicalSetting from "../components/TechnicalSetting";
import CommissionBitcoin from "../components/СommissionBitcoin";
import {editArray, editMerchants, getMerchants, updateMerchantOrder} from "../store/slices/merchantSlice";
import {editBots, editSetting, editSettings, getSettings} from "../store/slices/settingsSlice";
import {setSnackBar} from "../store/slices/uiSlice";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import _ from "lodash";
import axios from "axios";
import RegulationSetting from "../components/RegulationSetting";

// function Spinner(props) {
//     return null;
// }

// Spinner.propTypes = {accessibilityLabel: PropTypes.string};

function Settings() {
    const settings = useSelector((state) => state.settings.settings);
    const rawMerchants = useSelector((state) => state.merchant.merchants);
    const bots = useSelector((state) => state.settings.bots)
    const [course_type, setCourse_type] = React.useState(false);

    const [courses, setCourses] = React.useState([]);

    const merchants = useMemo(() => {
        return rawMerchants.slice().sort((a, b) => a.priority - b.priority);
    }, [rawMerchants]);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getSettings());
        dispatch(getMerchants());

        const fetchCourses = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api-admin/get-courses`);
            setCourses(response.data.data)
        }

        fetchCourses()
    }, []);

    React.useEffect(() => {
        if (settings.length > 0) {
            let course_typeVar = settings.find((item) => item.name === "course_type").value;
            console.log('course_typeVar', course_typeVar)
            if (course_typeVar == 'bybit') {
                console.log('test')
                setCourse_type(true)
            }
        }
    }, [settings]);



    const handleChangeType = (event) => {
        setCourse_type(event.target.checked);

        const courseTypeValue = event.target.checked ? 'bybit' : 'garantex';

        let course_typeVar = settings.find((item) => item.name === "course_type");
        let obj = _.clone(course_typeVar)
        obj.value = courseTypeValue
        dispatch(editSettings(obj));
    };


    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newMerchants = Array.from(merchants);
        const [reorderedItem] = newMerchants.splice(result.source.index, 1);
        newMerchants.splice(result.destination.index, 0, reorderedItem);

        // Обновление поля priority в соответствии с новым порядком
        const updatedMerchants = newMerchants.map((merchant, index) => ({
            ...merchant,
            priority: index + 1  // Устанавливаем приоритет начиная с 1
        }));

        dispatch(updateMerchantOrder(updatedMerchants));
    };


    async function onSubmit() {
        console.log("submit", settings);
        console.log("merchants", merchants);
        console.log("bots", bots);

        Promise.all([
            dispatch(editSetting(settings)).then(unwrapResult),
            dispatch(editMerchants(merchants)).then(unwrapResult),
            dispatch(editBots(bots)),
        ])
            .then((result) => {
                dispatch(
                    setSnackBar({
                        open: true,
                        text: "Настойки успешно сохранены",
                    })
                );
            })
            .catch((error) => {
                dispatch(
                    setSnackBar({
                        open: true,
                        text: "Ошибка при сохранении настроек",
                    })
                );
            });


    }

    return (
        <div>
            {settings.length == 0 || merchants.length == 0 ? (
                <div
                    style={{
                        height: "calc(100vh - 100px)",
                    }}
                    className="d-flex justify-content-center align-items-center "
                >
                    <CircularProgress size={20}/>
                    <Typography
                        style={{
                            marginLeft: 5,
                            marginTop: 0,
                        }}
                        fontSize="md"
                    >
                        Загрузка...
                    </Typography>
                </div>
            ) : (
                <div>
                    <div className="row ">
                        <div className="col-md-6 col-12">
                            <Card sx={{minWidth: 275, height: "100%"}}>
                                <CardContent className="text-start p-3">
                                    <Typography variant="h6" color="text.primary">
                                        Платежные системы
                                    </Typography>


                                    <div>
                                        <Typography variant="subtitle2" color="text.primary">
                                            Парсер курса
                                        </Typography>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography>Garantex <b>({courses?.find(item => item.name == 'garantex')?.price} ₽)</b></Typography>
                                            <Switch checked={course_type}
                                                    onChange={handleChangeType}/>
                                            <Typography>Bybit <b>({courses?.find(item => item.name == 'bybit')?.price} ₽)</b></Typography>
                                        </Stack>
                                    </div>


                                    <Typography variant="subtitle2" color="text.primary">
                                        Мерчи
                                    </Typography>

                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className="row"
                                                >
                                                    {merchants.map((merchant, index) => (
                                                        <Draggable key={merchant.id}
                                                                   draggableId={merchant.id.toString()} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className="col-12"
                                                                >
                                                                    <MerchantSetting key={merchant.id}
                                                                                     merchant={merchant}/>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>

                                </CardContent>
                            </Card>
                        </div>
                        <div className="col-md-6 col-12">
                            {/*<Card sx={{ minWidth: 275 }}>*/}
                            {/*  <CardContent className="text-start p-3">*/}
                            {/*    <Typography variant="h6" color="text.primary">*/}
                            {/*      Bitcoin система*/}
                            {/*    </Typography>*/}
                            {/*    <BalanceBitcoin />*/}
                            {/*    <CommissionBitcoin />*/}
                            {/*    <MinLimitBitcoin />*/}
                            {/*    <MaxLimitBitcoin />*/}
                            {/*    <PasswordBitcoin />*/}
                            {/*  </CardContent>*/}
                            {/*</Card>*/}

                            <Card sx={{minWidth: 275}}>
                                <CardContent className="text-start p-3">
                                    <Typography variant="h6" color="text.primary">
                                        Настройки автовывода
                                    </Typography>
                                    <AutowithdrawalSetting/>
                                </CardContent>
                            </Card>

                            <Card sx={{minWidth: 275}} className={"mt-3"}>
                                <CardContent className="text-start p-3">
                                    <Typography variant="h6" color="text.primary">
                                        Telegram уведомления
                                    </Typography>
                                    <BotSetting/>
                                </CardContent>
                            </Card>


                            <Card sx={{minWidth: 275}} className={"mt-3"}>
                                <CardContent className="text-start p-3">
                                    <Typography variant="h6" color="text.primary">
                                        Технические работы
                                    </Typography>
                                    <TechnicalSetting/>
                                </CardContent>
                            </Card>

                            <Card sx={{minWidth: 275}} className={"mt-3"}>
                                <CardContent className="text-start p-3">
                                    <Typography variant="h6" color="text.primary">
                                        Регламент
                                    </Typography>
                                    <RegulationSetting/>
                                </CardContent>
                            </Card>


                        </div>

                        <div className="col-12 mb-3">
                            <Button variant="outlined" className="mt-3" onClick={onSubmit}>
                                Сохранить
                            </Button>
                        </div>
                    </div>

                    {/*<div className="row mt-3">*/}
                    {/*  <div className="col-6">*/}
                    {/*    <BotSetting />*/}
                    {/*  </div>*/}
                    {/*  <div className="col-6">*/}
                    {/*    <TechnicalSetting />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                </div>
            )}
        </div>
    );
}

export default Settings;
