import React, {useEffect, useRef} from 'react';
import {Button, TextField, Typography} from "@mui/material";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import {editArrayBots, editSettings, getBots} from "../store/slices/settingsSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {isEqual} from "lodash";
import _ from 'lodash';


function BotSetting() {
    const dispatch = useDispatch();
    const bots = useSelector((state) => state.settings.bots)
    const settings = useSelector((state) => state.settings.settings)

    const {register, handleSubmit, control, watch, reset, setValue} = useForm(
        {
            defaultValues: {
                fieldArray: [],
                id: null,
                name: 'telegram_token',
                value: '',
            }
        }
    );

    const watchBots = watch(); // you can supply default value as second argument
    const watchFieldArray = watch("fieldArray");
    const prevFieldArray = useRef();
    const {fields, append, remove} = useFieldArray({
        control,
        name: "fieldArray",
        id: null
    });

    useEffect(() => {
        let dataSettings = {
            id: watchBots.id,
            name: watchBots.name,
            value: watchBots.value
        };
        dispatch(editSettings(dataSettings));
        if (!isEqual(_.cloneDeep(prevFieldArray.current), _.cloneDeep(watchFieldArray))) {
            dispatch(editArrayBots(watchFieldArray));
            prevFieldArray.current = _.cloneDeep(watchFieldArray); // Глубокое клонирование текущего состояния
        }
    }, [watchBots, watchFieldArray]);


    React.useEffect(() => {
        dispatch(getBots())
            .then(unwrapResult)
            .then((result) => {
                reset()
                let telegram_token = settings.find((item) => item.name === 'telegram_token')
                if (telegram_token) {
                    setValue('value', telegram_token.value)
                    setValue('id', telegram_token.id)
                }
                for (let i = 0; i < result.data.length; i++) {
                    append({chat_id: result.data[i].chat_id, id: result.data[i].id})
                }
                // prevBots.current = watchBots;  // Обновляем начальное значение после загрузки данных
            })
            .catch((error) => {

            });
    }, [])


    return (
        <>
            <div className="row mt-1">
                <Typography variant="subtitle2" color="text.primary">
                    Токен
                </Typography>

                <div className="d-flex align-items-center mt-3">

                    <Controller
                        name="value"
                        control={control}
                        render={({field}) =>
                            <TextField size="small" sx={{width: '100%'}}
                                       {...field}
                                       type="text"
                                       label="Токен"/>
                        }
                    />

                </div>


            </div>
            <div className="row mt-3">
                <Typography variant="subtitle2" color="text.primary">
                    IDs
                </Typography>

                <div>
                    {fields.map((field, index) =>
                        <div className="d-flex justify-content-between align-items-center mt-3" key={index}>
                            <div className="d-flex w-100  align-items-center">
                                <Controller
                                    name={`fieldArray.${index}.chat_id`}
                                    control={control}
                                    render={({field}) =>
                                        <TextField size="small" sx={{width: '100%'}}
                                                   {...field}
                                                   type="text"
                                                   label="ID"/>
                                    }
                                />
                            </div>
                            <div>
                                <DeleteIcon onClick={() => remove(index)}
                                            sx={{color: '#ff3d00', marginLeft: 1, cursor: 'pointer'}}/>
                            </div>
                        </div>
                    )}

                    <Button variant="outlined" className="mt-3"
                            onClick={() =>
                                append({
                                    chat_id: "",
                                })}>Добавить</Button>
                </div>
            </div>

            {/*<div className="d-flex  justify-content-center">*/}
            {/*    <Button*/}
            {/*        variant="outlined"*/}
            {/*        className="mt-1 mb-3 mx-1"*/}
            {/*        onClick={handleSubmit(onSubmit)}*/}
            {/*    >*/}
            {/*        Сохранить*/}
            {/*    </Button>*/}
            {/*</div>*/}

        </>
    );
}

export default BotSetting;
