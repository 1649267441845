import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {crUser, getAdmins, resetPass} from "../store/slices/userSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import {
    Button, Card, CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField,
    Typography
} from "@mui/material";
import {deleteUsers as delUsers} from "../store/slices/userSlice";
import {editUser as edUser} from "../store/slices/userSlice";

import Input from '@mui/material/Input';
import {useForm, Controller} from "react-hook-form";
import CustomToolbar from "../components/CustomToolbar";
import {DataGridPremium, GridActionsCellItem} from "@mui/x-data-grid-premium";
import localeGrid from "../components/localeGrid";
import {NavLink} from "react-router-dom";
import {unwrapResult} from "@reduxjs/toolkit";
import axios from "axios";
import {createNotification} from "../store/slices/notificationSlice";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import InfoIcon from "@mui/icons-material/Info";

const dayjs = require('dayjs')

function Admins() {
    const dispatch = useDispatch();
    const admins = useSelector((state) => state.user.admins)
    const [tableHeight, setTableHeight] = useState(100);
    const [selectionModel, setSelectionModel] = useState([]);
    const [deletedUsers, setDeletedUsers] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [passwordDialog, setPasswordDialog] = useState(false);
    const [balanceDialog, setBalanceDialog] = useState(false);
    const [addBalanceValue, setAddBalanceValue] = useState(0);
    const [openCreateNotificationDialog, setOpenCreateNotificationDialog] = React.useState(false);

    const merchants = useSelector((state) => state.auth.merchants)


    const {control, register, handleSubmit, setValue, watch, reset} = useForm({
        defaultValues: {
            id: 0,
            created_at: null,
            login: '',
            password: '',
            role: 'manager'
        }
    });

    const {
        control: controlNotification,
        handleSubmit: handleSubmitNotification,
        setValue: setValueNotification,
        reset: resetNotification,
    } = useForm({
        defaultValues: {
            title: '',
            description: '',
            severity: 'info',
            user_id: null,
        }
    });

    const watchEditUser = watch(); // you can supply default value as second argument


    const {
        control: controlCreate,
        register: registerCreate,
        handleSubmit: handleSubmitCreate,
        reset: resetCreate,
    } = useForm({
        defaultValues: {
            id: null,
            login: '',
            password: '',
            role: 'manager'
        }
    });


    const {
        control: controlResetPassword, handleSubmit: handleSubmitResetPassword, setValue: setValueResetPassword
    } = useForm({
        defaultValues: {
            password: '',
            id: 0
        }
    });


    const resize = async () => {
        console.log(window.innerHeight)
        setTableHeight(window.innerHeight - 200)
    }

    useEffect(() => {
        dispatch(getAdmins())
        resize()
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [])

    const deleteUser = useCallback(
        (id) => () => {
            let array = []
            array.push(Number(id))
            setDeletedUsers(array)
            setOpenDeleteDialog(true)
            // dispatch(delUsers(array))
        },
        []
    );


    const deleteSelected = () => {
        const array = selectionModel.map((id) => Number(id))
        // dispatch(delUsers(array))
        setOpenDeleteDialog(true)
        setDeletedUsers(array)
    };

    const addUser = () => {
        resetCreate()
        setOpenCreateDialog(true)
    };


    const editUser = useCallback(
        (row) => () => {
            console.log(row)
            reset()

            for (const property in row) {
                setValue(property, row[property])
            }

            setOpenEditDialog(true)
        },
        []
    );

    const onEditUser = data => {
        console.log(data)
        data.balance = Number(data.balance)
        dispatch(edUser(data))
            .then(unwrapResult)
            .then((result) => {
               dispatch(getAdmins())
                setOpenEditDialog(false)


                // navigate.push('/dashboard')
                // navigate("/dashboard", {replace: true});
            })
            .catch((error) => {
                setOpenEditDialog(false)

            });

    }

    const onCreateUser = data => {
        console.log(data)
        dispatch(crUser(data))
            .then(unwrapResult)
            .then((result) => {
                dispatch(getAdmins())
                setOpenCreateDialog(false)


                // navigate.push('/dashboard')
                // navigate("/dashboard", {replace: true});
            })
            .catch((error) => {
                setOpenCreateDialog(false)

            });
    }

    const onResetPassword = data => {
        console.log(data)
        dispatch(resetPass(data))
        setPasswordDialog(false)
    }

    function dialogDeleteUsers() {
        dispatch(delUsers(deletedUsers))
            .then(unwrapResult)
            .then((result) => {
                dispatch(getAdmins())
                setOpenDeleteDialog(false)


                // navigate.push('/dashboard')
                // navigate("/dashboard", {replace: true});
            })
            .catch((error) => {
                setOpenDeleteDialog(false)

            });
    }

    function dialogEditUser() {
        setOpenDeleteDialog(false)
    }


    const closeDeleteDialog = (event, reason) => {
        setSelectionModel([])
        setOpenDeleteDialog(false)
    }

    const closeEditDialog = (event, reason) => {
        setOpenEditDialog(false)
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 60
        },
        {
            field: 'login',
            headerName: 'Логин',
            width: 180
        },
        {
            field: 'role',
            headerName: 'Роль',
            width: 180
        },
        {
            field: 'created_at',
            headerName: "Дата регистрации",
            width: 150,
            valueFormatter: (params) => {
                return dayjs(params.value).format('DD.MM.YYYY HH:mm')
            }
        },
        {
            field: "actions",
            type: "actions",
            headerName: 'Действия',
            width: 150,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Удалить"
                    onClick={deleteUser(params.id)}
                />,
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Редактировать"
                    onClick={editUser(params.row)}
                />
            ]
        }

    ];

    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">
                    <Typography color="text.primary">
                        Администраторы
                    </Typography>
                    <div className="row">
                        <div className="col-12 justify-content-end text-end">

                            <Button variant="contained" className="" onClick={addUser}>
                                Добавить администратора
                            </Button>

                            <Button variant="contained" className="ms-3" onClick={deleteSelected}
                                    disabled={selectionModel.length == 0}>
                                Удалить выбранные
                            </Button>

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <div style={{height: tableHeight, width: '100%'}}>
                                <DataGridPremium
                                    rows={admins}
                                    columns={columns}
                                    components={{Toolbar: CustomToolbar}}
                                    componentsProps={{toolbar: {filename: 'Пользователи'}}}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    initialState={{
                                        sorting: {
                                            sortModel: [
                                                {
                                                    field: "id",
                                                    sort: "desc"
                                                }
                                            ]
                                        },
                                        pinnedColumns: {right: ['actions']}

                                    }}
                                    onSelectionModelChange={setSelectionModel}
                                    selectionModel={selectionModel} //
                                    localeText={localeGrid}

                                />
                            </div>
                        </div>
                    </div>
                    <Dialog
                        open={openDeleteDialog}
                        onClose={closeDeleteDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Подтвердить действие
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Удалить выбранных пользователей?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDeleteDialog}>Отмена</Button>
                            <Button onClick={() => dialogDeleteUsers()} autoFocus>
                                Да
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Dialog
                        open={openEditDialog}
                        onClose={closeEditDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Редактировать администратора
                        </DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={handleSubmit(onEditUser)}>
                                        <div className="mt-2">
                                            <Controller
                                                name="login"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        className="w-100"
                                                        label="Логин"
                                                        {...field}
                                                    />}
                                            />
                                        </div>


                                        <div className="mt-3">
                                            <FormControl style={{minWidth: '100%'}}>
                                                <InputLabel id="paySourcesFilter-label">Роль</InputLabel>
                                                <Controller
                                                    name="role"
                                                    id="role"
                                                    defaultValue={'manager'}
                                                    control={control}
                                                    render={({field}) => (
                                                        <Select labelId="level-label" {...field}>
                                                            <MenuItem value={'manager'}>Менеджер</MenuItem>
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="password"
                                                control={control}
                                                render={({field}) => <TextField
                                                    className="w-100"
                                                    // type="password"
                                                    label="Пароль"
                                                    {...field}
                                                />}
                                            />
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeEditDialog}>Отмена</Button>
                            <Button onClick={handleSubmit(onEditUser)} autoFocus>
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Dialog
                        open={openCreateDialog}
                        onClose={() => setOpenCreateDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Добавить администратора
                        </DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12">
                                    <form>
                                        <div className="mt-1">
                                            <Controller
                                                name="login"
                                                control={controlCreate}
                                                render={({field}) =>
                                                    <TextField
                                                        className="w-100"
                                                        label="Логин"
                                                        {...field}
                                                    />}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <FormControl style={{minWidth: '100%'}}>
                                                <InputLabel id="paySourcesFilter-label">Роль</InputLabel>
                                                <Controller
                                                    name="role"
                                                    id="role"
                                                    defaultValue={'manager'}
                                                    control={controlCreate}
                                                    render={({field}) => (
                                                        <Select labelId="level-label" {...field}>
                                                            <MenuItem value={'manager'}>Менеджер</MenuItem>
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="password"
                                                control={controlCreate}
                                                render={({field}) => <TextField
                                                    className="w-100"

                                                    label="Пароль"
                                                    {...field}
                                                />}
                                            />
                                        </div>

                                        <div className="mt-3">

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenCreateDialog(false)}>Отмена</Button>
                            <Button onClick={handleSubmitCreate(onCreateUser)} autoFocus>
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>


                </CardContent>
            </Card>
        </div>

    );
}

export default Admins;
