import {useLocation, Navigate, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";

const RequireAuth = ({allowedRoles}) => {
    // const {auth} = useAuth();
    const roles = useSelector((state) => state.auth.user).role.split(' ');

    console.log('roles', roles)
    const user = useSelector((state) => state.auth.user)

    const location = useLocation();

    return (
        roles.find(role => allowedRoles?.includes(role))
            ? <Outlet/>
            : user
                ? <Navigate to="/unauthorized" state={{from: location}} replace/>
                : <Navigate to="/login" state={{from: location}} replace/>
    );
}

export default RequireAuth;
