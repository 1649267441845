import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    user: null,
    system_settings: [],
    merchants: [],
    token: localStorage.getItem('token') || null,
    loading: 'IDLE',
    error: null
}


export const login = createAsyncThunk(
    'auth/login',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-admin/login`, data)

        console.log(response.data)
        return response.data
    }
)

export const logout = createAsyncThunk(
    'auth/logout',
    async (data) => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/api-admin/logout`)

        console.log(response.data)
        return response.data
    }
)

export const authorize = createAsyncThunk(
    'auth/authorize',
    async (token) => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/api-admin/authorize`, {
                headers: {
                    "Authorization": `${token}`
                }
            }
        )
        return response.data
    }
)

export const changePassword = createAsyncThunk(
    'auth/change-password',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-user/change-password`, data)

        console.log(response.data)
        return response.data
    }
)


export const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })


            .addCase(logout.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })

            .addCase(authorize.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })


            .addCase(login.fulfilled, (state, action) => {
                state.loading = 'idle';
                console.log('action.payload', action.payload)
                state.user = action.payload.user
                state.system_settings = action.payload.system_settings
                state.merchants = action.payload.merchants
                state.token = action.payload.token.token
                localStorage.setItem('token', `Bearer ${action.payload.token.token}`)
                axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.token.token}`;
                state.error = null;
            })


            .addCase(logout.fulfilled, (state, action) => {
                state.loading = 'idle';
                console.log('action.payload logout', action.payload)
                state.user = null
                state.token = null
                localStorage.removeItem('token')
                state.error = null;
            })

            .addCase(authorize.fulfilled, (state, action) => {
                state.loading = 'idle';
                console.log('action.payload authorize', action.payload)
                state.user = action.payload.data
                state.system_settings = action.payload.system_settings
                state.merchants = action.payload.merchants
                state.error = null;
            })


            .addCase(login.rejected, (state, action) => {
                state.user = null
                state.token = null
                localStorage.removeItem('token')
                state.loading = 'failed';
                state.error = action.payload.code;
            })

            .addCase(logout.rejected, (state, action) => {
                state.user = null
                state.token = null
                localStorage.removeItem('token')
                state.loading = 'failed';
                state.error = action.payload.code;
            })

            .addCase(authorize.rejected, (state, action) => {
                state.user = null
                state.token = null
                localStorage.removeItem('token')
                state.loading = 'failed';
                state.error = action.payload.code;
            })

    }
})

// Action creators are generated for each case reducer function
export const {} = authSlice.actions

export default authSlice.reducer
