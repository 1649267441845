import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import {DataGridPremium, GridActionsCellItem} from "@mui/x-data-grid-premium";
import {unwrapResult} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import CustomToolbar from "../components/CustomToolbar";
import localeGrid from "../components/localeGrid";
import {deleteOperations, editOperation, getOperations,} from "../store/slices/operationSlice";
import "../styles/operation.css"; // Tell webpack that Button.js uses these styles

var _ = require("lodash");

const currencies = [
  {
    value: "USDT_ERC",
    label: "USDT (ERC-20)",
  },
  {
    value: "USDT_TRC)",
    label: "USDT (TRC-20)",
  },
];

const statuses = [
  {
    value: "Успешно",
    label: "success",
  },
  {
    value: "Отмена",
    label: "decline",
  },
];

function Operations() {
  const [perPage, setPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [page, setPage] = useState(1);

  function returnTxLInk(txid, currency) {
    let link = "";
    switch (currency) {
      case "BTC":
        return (
          <div id="permalink_section">
            <a
              href={`https://www.blockchain.com/btc/tx/${txid}`}
              target="_blank"
              rel="noreferrer"
            >
              {txid}
            </a>
          </div>
        );
      case "USDT_TRC":
        return (
          <div id="permalink_section">
            <a
              href={`https://tronscan.org/#/transaction/${txid}`}
              target="_blank"
              rel="noreferrer"
            >
              {txid}
            </a>
          </div>
        );
      case "XMR":
        return (
          <div id="permalink_section">
            <a
              href={`https://xmrchain.net/tx/${txid}`}
              target="_blank"
              rel="noreferrer"
            >
              {txid}
            </a>
          </div>
        );
      case "LTC":
        return (
          <div id="permalink_section">
            <a
              href={`https://live.blockcypher.com/ltc/tx/${txid}`}
              target="_blank"
              rel="noreferrer"
            >
              {txid}
            </a>
          </div>
        );
      case "BANK":
        return <div>{txid}</div>;
    }
  }

  function returnAddressLInk(address, currency) {
    let link = "";
    switch (currency) {
      case "BTC":
        return (
          <div id="permalink_section">
            <a
              href={`https://www.blockchain.com/btc/address/${address}`}
              target="_blank"
              rel="noreferrer"
            >
              {address}
            </a>
          </div>
        );
      case "USDT_TRC":
        return (
          <div id="permalink_section">
            <a
              href={`https://tronscan.org/#/address/${address}`}
              target="_blank"
              rel="noreferrer"
            >
              {address}
            </a>
          </div>
        );
      case "XMR":
        return (
          <div id="permalink_section">
            <a
              href={`https://xmrchain.net/address/${address}`}
              target="_blank"
              rel="noreferrer"
            >
              {address}
            </a>
          </div>
        );
      case "LTC":
        return (
          <div id="permalink_section">
            <a
              href={`https://live.blockcypher.com/ltc/address/${address}`}
              target="_blank"
              rel="noreferrer"
            >
              {address}
            </a>
          </div>
        );
      case "BANK":
        return <div>{address}</div>;
    }
  }

  function returnStatus(status, is_deleted) {
    // console.log('status', status)
    let chip;
    switch (status) {
      case "new":
        chip = <Chip label="Новая" size="small" />;
        break;
      case "success":
        chip = <Chip label="Успешно" size="small" color="success" />;
        break;
      case "wait_delete":
        chip = <Chip label="Ожидает отмены" size="small" color="warning"/>;
        break;
      case "decline":
        chip = (
          <Chip
            label="Отменена"
            size="small"
            sx={{
              background: "#f44336",
            }}
          />
        );
        break;
      default:
        chip = <Chip label="Неизвестно" size="small" />;
        break;
    }

    let chipDeleted;
    if (is_deleted) {
      chipDeleted = (
        <Chip
          label="Удалена клиентом"
          size="small"
          className="mx-1"
          sx={{
            background: "#f44336",
          }}
        />
      );
    }

    return (
      <div className="d-flex">
        {chip}
        {chipDeleted}
      </div>
    );
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 75,
    },

    {
      field: "type",
      headerName: "Тип",
      width: 150,
      renderCell: (params) =>
        params.value == "withdraw" ? (
          <Chip label="Ручной вывод" size="small" color="primary" />
        ) : (
          <Chip label="Автовывод" size="small" color="secondary" />
        ),
    },

    {
      field: "amount",
      headerName: "Сумма",
      width: 100,
    },

    {
      field: "status",
      headerName: "Статус",
      width: 250,
      renderCell: (params) => returnStatus(params.value, params.row.is_deleted),
    },

    {
      field: "comment",
      headerName: "Комментарий/ Банк",
      width: 120,
    },
    {
      field: "admin_comment",
      headerName: "Комментарий админа",
      width: 180,
    },
    {
      field: "address",
      headerName: "Адрес/Карта/Телефон",
      width: 250,
      renderCell: (params) =>
        returnAddressLInk(params.row.address, params.row.currency),
    },
    {
      field: "currency",
      headerName: "Валюта",
      width: 120,
    },
    {
      field: "txid",
      headerName: "Txid",
      width: 250,
      renderCell: (params) =>
        returnTxLInk(params.row.txid, params.row.currency),
    },
    {
      field: "user",
      headerName: "Пользователь",
      width: 150,
      // valueGetter: (params) => {
      //   return params.value.login;
      // },
    },

    // {
    //     field: 'tx_id',
    //     headerName: 'Транзакция',
    //     width: 120
    // },
    {
      field: "created_at",
      headerName: "Дата создания",
      width: 150,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Действия",
      width: 150,
      getActions: (params) => returnArrayActions(params),
    },
  ];

  function returnArrayActions(params) {
    if (params.row.is_deleted) {
      return [];
    }
    if (params.row.status == "new") {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Удалить"
          onClick={deleteOperation(params.id)}
        />,
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label="Просмотреть"
          onClick={editOperations(params.row)}
        />,
        <GridActionsCellItem
          icon={<DoneIcon color="success" />}
          label="Выполнено"
          onClick={edOperation(params.row, "success")}
        />,
        <GridActionsCellItem
          icon={<CloseIcon color="error" />}
          label="Отмена"
          onClick={edOperation(params.row, "decline")}
        />,
      ];
    } else if (params.row.status == "wait_delete") {
      return [
        <GridActionsCellItem
            icon={<DoneIcon/>}
            label="Подвердить отмену"
            onClick={approveCancelOperation(params, true)}
        />,
        // <GridActionsCellItem
        //     icon={<CloseIcon color="error"/>}
        //     label="Отклонить отмену"
        //     onClick={approveCancelOperation(params, false)}
        // />,
      ];
    } else {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Удалить"
          onClick={deleteOperation(params.id)}
        />,
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label="Просмотреть"
          onClick={editOperations(params.row)}
        />,
      ];
    }
  }

  const [tableHeight, setTableHeight] = React.useState(
    window.innerHeight - 200
  );
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const operations = useSelector((state) => state.operation.operations);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [deletedOperations, setDeletedOperations] = React.useState([]);
  const [openShowDialog, setOpenShowDialog] = React.useState(false);
  const [checkedAddressFromUser, setCheckedAddressFromUser] =
    React.useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
  const [openDeclineDialog, setOpenDeclineDialog] = React.useState(false);

  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);



  React.useEffect(() => {
    if (checkedAddressFromUser) {
      setValue("address", user.address);
    } else {
      setValue("address", "");
    }
  }, [checkedAddressFromUser]);

  const resize = async () => {
    console.log(window.innerHeight);
    setTableHeight(window.innerHeight - 200);
  };

  React.useEffect(() => {
    dispatch(
      getOperations({ page: 1, perPage: perPage, searchTerm: searchTerm })
    )
      .then(unwrapResult)
      .then((result) => {
        console.log(result); // => 233
        setRows(result.data.data);
      })
      .catch((error) => {
        console.error(error); // if there is an error
      });

    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
      // clearInterval(intervalId);
    };
  }, []);

  // React.useEffect(() => {
  //   dispatch(getOperations());
  //   resize();
  //   window.addEventListener("resize", resize);
  //   return () => {
  //     window.removeEventListener("resize", resize);
  //   };
  // }, []);


  const approveCancelOperation = React.useCallback(
      (row, approve) => () => {
        console.log(row)
        // row.status = status
        if (approve) {
          resetCancel();
          setValueCancel("id", row.id);
          setValueCancel("user_id", row.row.user_id);
          setValueCancel("amount", row.row.amount);

          setValueCancel("is_deleted", true);
          setValueCancel("status", 'decline');
          setValueCancel("approve", true);

          setOpenCancelDialog(true);
        } else {
          resetCancel();
          setValueCancel("id", row.id);
          setValueCancel("user_id", row.row.user_id);
          setValueCancel("amount", row.row.amount);

          setValueCancel("is_deleted", false);
          setValueCancel("status", 'new');
          setValueCancel("approve", false);
          setOpenCancelDialog(true);
        }
      },
      []
  );

  const onCancelOperation = (data) => {
    console.log(data);
    delete data.approve;

    dispatch(editOperation(data))
        .then(unwrapResult)
        .then((result) => {
          setOpenCancelDialog(false);
          dispatch(
              getOperations({page: page, perPage: perPage, searchTerm: searchTerm})
          )
              .then(unwrapResult)
              .then((result) => {
                console.log(result); // => 233
                setRows(result.data.data);
              })
              .catch((error) => {
                console.error(error); // if there is an error
              });

        });
  };


  const deleteOperation = React.useCallback(
    (id) => () => {
      console.log(id);
      let array = [];
      array.push(Number(id));
      setDeletedOperations(array);
      setOpenDeleteDialog(true);
    },
    []
  );




  const editOperations = React.useCallback(
    (row) => () => {
      console.log(row);

      for (const rowKey in row) {
        setValue(rowKey, row[rowKey]);
      }
      setOpenEditDialog(true);
    },
    []
  );

  const showClientInfo = React.useCallback(
    (row) => () => {
      console.log(row);
    },
    []
  );

  const onEditOperation = (data) => {
    console.log(data);
  };

  const edOperation = React.useCallback(
    (row, status) => () => {
      // row.status = status
      console.log(row);
      if (status == "success") {
        resetSuccess();
        setValueSuccess("id", row.id);
        setValueSuccess("status", status);
        setValueSuccess("user_id", row.user_id);
        setValueSuccess("amount", row.amount);
        setOpenSuccessDialog(true);
      } else {
        resetDecline();
        setValueDecline("id", row.id);
        setValueDecline("user_id", row.user_id);
        setValueDecline("status", status);
        setValueDecline("amount", row.amount);
        setOpenDeclineDialog(true);
      }
    },
    []
  );

  const onSuccessOperation = (data) => {
    dispatch(editOperation(data))
      .then(unwrapResult)
      .then((result) => {
        setOpenSuccessDialog(false);

        dispatch(
          getOperations({
            page: page,
            perPage: perPage,
            searchTerm: searchTerm,
          })
        )
          .then(unwrapResult)
          .then((result) => {
            console.log(result); // => 233
            setRows(result.data.data);
          })
          .catch((error) => {
            console.error(error); // if there is an error
          });
      });
  };

  const onDeclineOperation = (data) => {
    console.log(data);

    dispatch(editOperation(data))
      .then(unwrapResult)
      .then((result) => {
        setOpenDeclineDialog(false);

        dispatch(
          getOperations({
            page: page,
            perPage: perPage,
            searchTerm: searchTerm,
          })
        )
          .then(unwrapResult)
          .then((result) => {
            console.log(result); // => 233
            setRows(result.data.data);
          })
          .catch((error) => {
            console.error(error); // if there is an error
          });
      });

    // dispatch(editOperation(data));

    // // dispatch(createOperation(data))
    // setOpenDeclineDialog(false);
  };

  const deleteSelected = () => {
    const array = selectionModel.map((id) => Number(id));
    setDeletedOperations(array);
    // dispatch(deleteTokens(array))
    setOpenDeleteDialog(true);
  };

  const {
    control: controlSuccess,
    handleSubmit: handleSubmitSuccess,
    setValue: setValueSuccess,
    reset: resetSuccess,
  } = useForm({
    defaultValues: {
      id: null,
      status: "new",
      admin_comment: "",
      txid: "",
      user_id: null,
      amount: 0,
    },
  });

  const {
    control: controlDecline,
    handleSubmit: handleSubmitDecline,
    setValue: setValueDecline,
    reset: resetDecline,
  } = useForm({
    defaultValues: {
      id: null,
      user_id: null,
      status: "new",
      admin_comment: "",
      amount: 0,
    },
  });

  const {
    control: controlCancel,
    handleSubmit: handleSubmitCancel,
    setValue: setValueCancel,
    reset: resetCancel,
    watch: watchCancel
  } = useForm({
    defaultValues: {
      id: null,
      user_id: null,
      is_deleted: false,
      status: "new",
      admin_comment: "",
      approve: false,
      amount: 0,
    },
  });

  let approve = watchCancel("approve", false);

  const { register, control, handleSubmit, setValue, reset, getValues } =
    useForm({
      defaultValues: {
        id: null,
        type: "withdraw",
        amount: 0,
        status: "new",
        comment: "",
        address: "",
        currency: currencies[0].value,
      },
    });

  const closeDeleteDialog = (event, reason) => {
    setSelectionModel([]);
    setOpenDeleteDialog(false);
  };

  function dialogDeleteOperations() {
    console.log(deletedOperations);
    dispatch(deleteOperations(deletedOperations))
      .then(unwrapResult)
      .then((result) => {
        setOpenDeleteDialog(false);

        dispatch(
          getOperations({
            page: page,
            perPage: perPage,
            searchTerm: searchTerm,
          })
        )
          .then(unwrapResult)
          .then((result) => {
            console.log(result); // => 233
            setRows(result.data.data);
          })
          .catch((error) => {
            console.error(error); // if there is an error
          });
      });
  }

  const closeEditDialog = (event, reason) => {
    setOpenEditDialog(false);
  };

  const postRowStyle = (record, index) => ({
    backgroundColor: "#00ff00",
  });

  const handlePageSizeChange = (newPerPage) => {
    setPerPage(newPerPage);

    dispatch(
      getOperations({
        page: page,
        perPage: perPage,
        newPerPage: newPerPage,
        searchTerm: searchTerm,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        console.log(result); // => 233
        setRows(result.data.data);
      })
      .catch((error) => {
        console.error(error); // if there is an error
      });
  };

  const handleFilterModelChange = (model) => {
    console.log(model.quickFilterValues[0]);
    setSearchTerm(model.quickFilterValues[0]);
    dispatch(
      getOperations({
        page: page,
        perPage: perPage,
        searchTerm: model.quickFilterValues[0],
      })
    )
      .then(unwrapResult)
      .then((result) => {
        console.log(result); // => 233
        setRows(result.data.data);
      })
      .catch((error) => {
        console.error(error); // if there is an error
      });
  };

  function handlePageChange(page) {
    // const {page} = params

    setPage(page + 1);
    // console.log('test', params)
    dispatch(
      getOperations({
        page: page + 1,
        perPage: perPage,
        searchTerm: searchTerm,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        console.log(result); // => 233
        setRows(result.data.data);
      })
      .catch((error) => {
        console.error(error); // if there is an error
      });
  }

  return (
    <div>
      <Card sx={{ minWidth: 275 }}>
        <CardContent className="text-start p-3">
          <Typography color="text.primary">Операции</Typography>

          <div className="row">
            <div className="col-12 justify-content-end text-end">
              {/*<Button variant="contained" className="" onClick={startCreateOperation}>*/}
              {/*    Заявка на вывод средств*/}
              {/*</Button>*/}

              <Button
                variant="contained"
                className="ms-3"
                onClick={deleteSelected}
                disabled={selectionModel.length == 0}
              >
                Удалить выбранные
              </Button>к
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-3">
              <div style={{ height: tableHeight, width: "100%" }}>
                <DataGridPremium
                  paginationMode="server"
                  pageSize={perPage}
                  rowHeight={80}
                  disableColumnFilter
                  onPageSizeChange={handlePageSizeChange}
                  rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                  pagination
                  onPageChange={handlePageChange}
                  onFilterModelChange={handleFilterModelChange}
                  rowCount={
                    operations.meta?.total != null ? operations.meta?.total : 0
                  }
                  getRowClassName={(params) => `color-${params.row.status}`}
                  rows={rows}
                  columns={columns}
                  components={{ Toolbar: CustomToolbar }}
                  componentsProps={{ toolbar: { filename: "Операции" } }}
                  checkboxSelection
                  disableSelectionOnClick
                  initialState={{
                    sorting: {
                      sortModel: [
                        {
                          field: "id",
                          sort: "desc",
                        },
                      ],
                    },
                    pinnedColumns: { right: ["actions"] },
                  }}
                  onSelectionModelChange={setSelectionModel}
                  selectionModel={selectionModel} //
                  localeText={localeGrid}
                />
              </div>
            </div>
          </div>

          <Dialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Подтвердить действие
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Удалить выбранные операции?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDeleteDialog(false)}>Отмена</Button>
              <Button onClick={() => dialogDeleteOperations()} autoFocus>
                Да
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openEditDialog}
            maxWidth="sm"
            fullWidth={true}
            onClose={() => setOpenEditDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Заявка на вывод</DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-12 mt-3">
                  <form>
                    <div>
                      <Controller
                        name="amount"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            className="w-100"
                            type="number"
                            disabled={true}
                            label="Сумма"
                            {...field}
                          />
                        )}
                      />
                    </div>

                    <div className="mt-3">
                      <Controller
                        name="comment"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            className="w-100"
                            disabled={true}
                            label="Комментарий"
                            {...field}
                          />
                        )}
                      />
                    </div>

                    <div className="mt-3">
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            className="w-100"
                            disabled={true}
                            label="Адрес"
                            {...field}
                          />
                        )}
                      />
                    </div>

                    {/*<div className="mt-3">*/}
                    {/*    <FormControl fullWidth>*/}
                    {/*        <InputLabel id="currency-label">Валюта</InputLabel>*/}
                    {/*        <Controller*/}
                    {/*            name="currency"*/}
                    {/*            control={control}*/}
                    {/*            render={({field}) =>*/}
                    {/*                <Select*/}
                    {/*                    {...field}*/}
                    {/*                    disabled={true}*/}
                    {/*                    value={field.value}*/}
                    {/*                    labelId="currency-label"*/}
                    {/*                >*/}
                    {/*                    {currencies.map((option) => (*/}
                    {/*                        <MenuItem key={option.value} value={option.value}>*/}
                    {/*                            {option.label}*/}
                    {/*                        </MenuItem>*/}
                    {/*                    ))}*/}
                    {/*                </Select>}*/}
                    {/*        />*/}
                    {/*    </FormControl>*/}
                    {/*</div>*/}
                  </form>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenEditDialog(false)}>Закрыть</Button>
              {/*<Button onClick={handleSubmit(onEditOperation)}>*/}
              {/*    Сохранить*/}
              {/*</Button>*/}
            </DialogActions>
          </Dialog>

          <Dialog
            open={openSuccessDialog}
            maxWidth="sm"
            fullWidth={true}
            onClose={() => setOpenSuccessDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Подтвердить выплату
            </DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-12 mt-3">
                  <form>
                    <div>
                      <Controller
                        name="txid"
                        control={controlSuccess}
                        render={({ field }) => (
                          <TextField
                            className="w-100"
                            type="text"
                            label="TXID"
                            {...field}
                          />
                        )}
                      />
                    </div>

                    <div className="mt-3">
                      <Controller
                        name="admin_comment"
                        control={controlSuccess}
                        render={({ field }) => (
                          <TextField
                            className="w-100"
                            type="text"
                            label="Комментарий"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenSuccessDialog(false)}>
                Закрыть
              </Button>
              <Button onClick={handleSubmitSuccess(onSuccessOperation)}>
                Сохранить
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openDeclineDialog}
            maxWidth="sm"
            fullWidth={true}
            onClose={() => setOpenDeclineDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Отменить выплату</DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-12 mt-3">
                  <form>
                    <div>
                      <Controller
                        name="admin_comment"
                        control={controlDecline}
                        render={({ field }) => (
                          <TextField
                            className="w-100"
                            type="text"
                            label="Комментарий"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDeclineDialog(false)}>
                Закрыть
              </Button>
              <Button onClick={handleSubmitDecline(onDeclineOperation)}>
                Сохранить
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
              open={openCancelDialog}
              maxWidth="sm"
              fullWidth={true}
              onClose={() => setOpenCancelDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {approve == true ? 'Подтвердить отмену выплаты' : 'Отклонить отмену выплаты'}
            </DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-12 mt-3">
                  <form>
                    <div>
                      <Controller
                          name="admin_comment"
                          control={controlCancel}
                          render={({field}) => (
                              <TextField
                                  className="w-100"
                                  type="text"
                                  label="Комментарий"
                                  {...field}
                              />
                          )}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenCancelDialog(false)}>
                Закрыть
              </Button>
              <Button onClick={handleSubmitCancel(onCancelOperation)}>
                Сохранить
              </Button>
            </DialogActions>
          </Dialog>


        </CardContent>
      </Card>
    </div>
  );
}

export default Operations;
