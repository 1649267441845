import React from 'react';
import Header from "../layouts/Header";
import ChangePassword from "../components/ChangePassword";

function Profile() {
    return (
        <div>
            <div className="row">
                <div className="col-4">
                    <ChangePassword/>

                </div>
            </div>
        </div>

    );
}

export default Profile;
