import React from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider, Fade, FormControl,
    FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, Step, StepLabel, Stepper,
    TextField,
    Typography
} from "@mui/material";
import {useForm, Controller} from "react-hook-form";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import CustomToolbar from "../components/CustomToolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {createRequest, getRequests, payedRequest} from "../store/slices/requestSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import '../styles/request.css'
import {DataGridPremium, GridActionsCellItem} from "@mui/x-data-grid-premium";
import localeGrid from "../components/localeGrid";

const steps = ['Новая заявка', 'Оплата'];

function ClearRequests() {

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 75
        },
        {
            field: 'user',
            headerName: 'User',
            width: 75
        },


        {
            field: 'URI',
            headerName: 'URI',
            width: 70
        },

        {
            field: 'address',
            headerName: 'Адрес',
            width: 250,
            renderCell: (params) =>
                <a href={`https://mempool.space/address/${params.row.address}`} target="_blank">{params.row.address}</a>,
        },

        {
            field: 'amount_btc',
            headerName: 'Сумма (BTC)',
            width: 100,
            valueFormatter: (params) => {
                if (params.value) {
                    return (params.value).toFixed(8)
                } else {
                    return 0
                }
            }
        },
        {
            field: 'amount_btc_send',
            headerName: 'Сумма к выплате(BTC)',
            width: 150,
            valueFormatter: (params) => {
                if (params.value) {
                    return (params.value).toFixed(8)
                } else {
                    return 0
                }
            }
        },
        {
            field: 'confirmations',
            headerName: 'Подтверждения',
            width: 100
        },
        {
            field: 'txid',
            headerName: 'TXID',
            width: 250,
            renderCell: (params) =>
                <a href={`https://mempool.space/tx/${params.row.txid}`} target="_blank">{params.row.txid}</a>,
        },

        {
            field: 'dist_address',
            headerName: 'Адрес назначения',
            width: 250,
            renderCell: (params) =>
                <a href={`https://mempool.space/address/${params.row.dist_address}`} target="_blank">{params.row.dist_address}</a>,
        },
        {
            field: 'status',
            headerName: 'Статус',
            width: 200,
            valueFormatter: (params) => {
                switch (params.value) {
                    case 0:
                        return 'Новая. Ожидает оплаты';
                    case 1:
                        return 'Время оплаты истекло';
                    case 7:
                        return 'Ожидание подтверждений';
                    case 3:
                        return 'Оплачено';
                    default:
                        return 'Неизвестно';
                }
            }
        },
        {
            field: 'created_at',
            headerName: "Дата создания",
            width: 150,
            valueFormatter: (params) => {
                return dayjs(params.value).format('DD.MM.YYYY HH:mm')
            }
        },
        {
            field: "actions",
            type: "actions",
            headerName: 'Действия',
            width: 100,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<VisibilityIcon/>}
                    label="Посмотреть информацию о клиенте"
                    onClick={showInfo(params.row)}
                />
            ]
        }
    ];
    const [tableHeight, setTableHeight] = React.useState(window.innerHeight);
    const dispatch = useDispatch();
    const commission_btc = useSelector((state) => {
        let array = state.auth.system_settings
        for (let i = 0; i < array.length; i++) {
            if (array[i].name == 'commission_btc') {
                return array[i].value
            }
        }
    })
    const requests = useSelector((state) => state.request.requests)
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [openCreateRequest, setOpenCreateRequest] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);

    const [activeRequestIndex, setActiveRequestIndex] = React.useState(null);


    const resize = async () => {
        console.log(window.innerHeight)
        setTableHeight(window.innerHeight - 200)
    }

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(getRequests())
            } catch (error) {
                console.log(error);
            }
        };
        const id = setInterval(() => {
            console.log('fetching data')
            fetchData(); // <-- (3) invoke in interval callback
        }, 60000 * 5);


        dispatch(getRequests())
        resize()
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize);
        }
        return () => clearInterval(id);

    }, [])


    const showInfo = React.useCallback(
        (row) => () => {
            console.log(row)
            const index = requests.findIndex(object => {
                return object.id === row.id;
            });
            setActiveRequestIndex(index)
            if (row.status === 0 || row.status === 7) {
                setActiveStep(1)
            }
            if (row.status === 3 || row.status === 1) {
                setActiveStep(2)
            }

            setOpenCreateRequest(true)
        },
        [requests]
    );


    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        watch
    } = useForm({
        defaultValues: {
            amount: 0,
            dist_address: '',
        }
    });

    const watchAmount = watch("amount", 0); // you can supply default value as second argument


    const onCreateRequest = data => {
        console.log(data)
        // dispatch(createOperation(data))
        // setOpenCreateRequest(false)
    }

    function startCreateRequest() {
        reset()
        setActiveStep(0)
        setOpenCreateRequest(true)
    }


    const isStepOptional = (step) => {
        return step === 1;
    };

    const handleNext = (data) => {
        console.log(data)
        // if (activeStep === 0) {
        //     dispatch(createRequest(data))
        //         .then(unwrapResult)
        //         .then((result) => {
        //             console.log(result); // => 233
        //
        //
        //             let arr = result.data
        //
        //             const index = arr.findIndex(object => {
        //                 return object.id === result.request.id;
        //             });
        //             console.log(index)
        //             //
        //             setActiveRequestIndex(index)
        //             setActiveStep(1)
        //         })
        //         .catch((error) => {
        //             console.error(error); // if there is an error
        //         });
        // }
    };

    const handleReset = () => {
        setOpenCreateRequest(false)
        setActiveStep(0);
    };


    function getSendAmount(amount) {
        let percent = (100 - Number(commission_btc)) / 100
        console.log('percent', percent)
        return amount * percent
    }


    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">
                    <Typography color="text.primary">
                        Чистка BTC
                    </Typography>

                    <div className="row">
                        <div className="col-12 justify-content-end text-end">

                            {/*<Button variant="contained" className="" onClick={startCreateRequest}>*/}
                            {/*    Новая заявка*/}
                            {/*</Button>*/}

                            {/*<Button variant="contained" className="ms-3" onClick={deleteSelected}*/}
                            {/*        disabled={selectionModel.length == 0}>*/}
                            {/*    Удалить выбранные*/}
                            {/*</Button>*/}


                        </div>

                    </div>

                    <div className="row">
                        <div className="col-12 mt-3">
                            <div style={{height: tableHeight, width: '100%'}}>
                                <DataGridPremium
                                    rows={requests}
                                    // onCellDoubleClick={(params, event) => {
                                    //     console.log('test', params)
                                    //     switch (params.field) {
                                    //         case 'address':
                                    //             if (params.value) {
                                    //                 window.open(`https://mempool.space/address/${params.value}`, '_blank')
                                    //             }
                                    //             break;
                                    //         case 'dist_address':
                                    //             if (params.value) {
                                    //                 window.open(`https://mempool.space/address/${params.value}`, '_blank')
                                    //             }
                                    //             break;
                                    //         case 'txid':
                                    //             if (params.value) {
                                    //                 window.open(`https://mempool.space/tx/${params.value}`, '_blank')
                                    //             }
                                    //             break;
                                    //     }
                                    // }}
                                    rowHeight={80}
                                    columns={columns}
                                    components={{Toolbar: CustomToolbar}}
                                    componentsProps={{toolbar: {filename: 'Операции'}}}
                                    // checkboxSelection
                                    getRowClassName={(params) => `color-${params.row.status}`}

                                    disableSelectionOnClick
                                    initialState={{
                                        sorting: {
                                            sortModel: [
                                                {
                                                    field: "id",
                                                    sort: "desc"
                                                }
                                            ]
                                        },
                                        pinnedColumns: {right: ['actions']}

                                    }}
                                    onSelectionModelChange={setSelectionModel}
                                    selectionModel={selectionModel} //
                                    localeText={localeGrid}

                                />
                            </div>
                        </div>
                    </div>


                    <Dialog
                        open={openCreateRequest}
                        maxWidth="sm"
                        fullWidth={true}
                        onClose={() => setOpenCreateRequest(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Заявка на вывод
                        </DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <Stepper activeStep={activeStep} alternativeLabel>
                                        {steps.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>


                                    {activeStep === steps.length &&
                                        <React.Fragment>
                                            <div className="mt-3 justify-content-center">


                                                {requests[activeRequestIndex].status == 1 &&
                                                    <div className="text-center">
                                                        <b>Время заявки истекло</b>
                                                    </div>
                                                }
                                                {requests[activeRequestIndex].status == 3 &&
                                                    <div>


                                                        <div className="text-center">
                                                            Заявка успешно исполнена
                                                        </div>
                                                        <div className="mt-3">
                                                            <b>txid:</b> {requests[activeRequestIndex].txid} <br/>
                                                            <b> Выплачено:</b> {requests[activeRequestIndex].amount_btc_send}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                    <React.Fragment>
                                        <Box sx={{display: 'flex', flexDirection: 'column', pt: 2}}>
                                            {activeStep === 0 &&
                                                <form>
                                                    <div className="w-100 d-flex flex-column">
                                                        <Controller
                                                            name="amount"
                                                            rules={{
                                                                required: "Поле обязательно для заполнения",
                                                            }}
                                                            control={control}
                                                            render={({field, fieldState}) =>
                                                                <TextField
                                                                    className="w-100"
                                                                    type="number"
                                                                    error={!!fieldState.error}
                                                                    helperText={fieldState.error?.message}
                                                                    label="Сумма"
                                                                    {...field}
                                                                />}
                                                        />
                                                    </div>
                                                    <div>
                                                        К выплате: {getSendAmount(watchAmount)} (Коммисия
                                                        сервиса: {commission_btc} %)
                                                    </div>


                                                    <div className="mt-3">
                                                        <Controller
                                                            name="dist_address"
                                                            control={control}
                                                            rules={{
                                                                required: "Поле обязательно для заполнения",
                                                                pattern: {
                                                                    value: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/g,
                                                                    message: "Неверный адрес"
                                                                }
                                                            }}

                                                            render={({field, fieldState}) =>
                                                                <TextField
                                                                    className="w-100"
                                                                    error={!!fieldState.error}
                                                                    helperText={fieldState.error?.message}
                                                                    label="Адрес получения "
                                                                    {...field}
                                                                />}
                                                        />
                                                    </div>
                                                </form>
                                            }

                                            {activeStep === 1 &&
                                                <div>


                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <b>Адрес: </b>
                                                        </div>
                                                        <div>
                                                            {requests[activeRequestIndex].address}
                                                        </div>
                                                        <div className="mx-1">
                                                            <ContentPasteIcon
                                                                sx={{cursor: 'pointer'}}

                                                                onClick={() => navigator.clipboard.writeText(requests[activeRequestIndex].address)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="d-flex mt-3 align-items-center">
                                                        <div>
                                                            <b>Сумма: </b>
                                                        </div>
                                                        <div>
                                                            {requests[activeRequestIndex].amount_btc}
                                                        </div>
                                                        <div className="mx-1">
                                                            <ContentPasteIcon
                                                                sx={{cursor: 'pointer'}}
                                                                onClick={() => navigator.clipboard.writeText(requests[activeRequestIndex].amount_btc)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <Divider className="my-3"/>
                                                    <div>
                                                        <div className="d-flex align-items-center">
                                                            <b>Количество
                                                                подтверждений:</b> {requests[activeRequestIndex].confirmations}
                                                            <CircularProgress className="mx-1" size={20}/>
                                                        </div>
                                                        <div>
                                                            Выплата будет произведена после 2-х подтверждений
                                                            автоматически
                                                            <div>
                                                                К
                                                                выплате: {getSendAmount(requests[activeRequestIndex].amount_btc)} (Коммисия
                                                                сервиса: {commission_btc} %)
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <div className="ms-auto mt-3">
                                                {activeStep == 0 &&
                                                    <Button onClick={handleSubmit(handleNext)}>
                                                        Далее
                                                    </Button>
                                                }
                                                {(activeStep == 1 || activeStep == 2) &&
                                                    <Button onClick={handleReset}>
                                                        Закрыть
                                                    </Button>
                                                }

                                            </div>
                                        </Box>
                                    </React.Fragment>


                                </div>
                            </div>
                        </DialogContent>
                        {/*<DialogActions>*/}
                        {/*    <Button onClick={() => setOpenCreateRequest(false)}>Отмена</Button>*/}
                        {/*    <Button onClick={handleSubmit(onCreateRequest)}>*/}
                        {/*        Сохранить*/}
                        {/*    </Button>*/}
                        {/*</DialogActions>*/}
                    </Dialog>

                </CardContent>
            </Card>
        </div>
    );
}

export default ClearRequests;
