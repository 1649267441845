import {Link, Outlet} from 'react-router-dom';
import Header from "./Header";
import React from "react";

const SharedLayout = () => {
    return (
        <>
            <Header/>
            <div className="container-fluid mt-3">
                <Outlet/>
            </div>
        </>
    );
};
export default SharedLayout;
