export default {

    noResultsOverlayLabel: 'Нет найденных данных',
    errorOverlayDefaultLabel: 'Ошибка.',
    footerTotalRows: 'Всего строк:',

    toolbarDensity: "Размер",
    toolbarDensityLabel: "Размер",
    toolbarDensityCompact: "Маленький",
    toolbarDensityStandard: "Средний",
    toolbarDensityComfortable: "Большой",

    noRowsLabel: 'Нет данных',


    // Columns selector toolbar button text
    toolbarColumns: "Столбцы",
    toolbarColumnsLabel: "Выбрать столбцы",
    checkboxSelectionHeaderName: "Выбор строк",
    // Columns panel text
    columnsPanelTextFieldLabel: "Найти столбец",
    columnsPanelTextFieldPlaceholder: "Название столбца",
    columnsPanelDragIconLabel: "Reorder column",
    columnsPanelShowAllButton: "Показать все",
    columnsPanelHideAllButton: "Скрыть все",
    // Export
    toolbarExport: 'Экспорт',
    toolbarExportLabel: 'Экспорт',
    toolbarExportCSV: 'Скачать в CSV',
    toolbarExportPrint: 'Напечатать',

    columnMenuLabel: 'Меню',
    columnMenuShowColumns: 'Показать колонки',
    columnMenuFilter: 'Фильтер',
    columnMenuHideColumn: 'Скрыть',
    columnMenuUnsort: 'Не сортировать',
    columnMenuSortAsc: 'Сортировать по возрастанию',
    columnMenuSortDesc: 'Сортировать по убыванию',
    toolbarExportExcel: 'Скачать в Excel',

    toolbarQuickFilterPlaceholder: 'Поиск…',
    toolbarQuickFilterLabel: 'Поиск',
    toolbarQuickFilterDeleteIconLabel: 'Очистить',

    footerRowSelected: (count) =>
        count !== 1
            ? `${count.toLocaleString()} строк выбрано`
            : `${count.toLocaleString()} строка выбрана`,

}