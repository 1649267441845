import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  settings: [],
  bots: [],
  loading: "IDLE",
  error: null,
};

export const getSettings = createAsyncThunk("settings/get", async (data) => {
  let response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api-admin/get-settings`
  );
  console.log(response.data);
  return response.data;
});

export const editSetting = createAsyncThunk("settings/edit", async (data) => {
  let response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api-admin/edit-setting`,
    data
  );
  console.log(response.data);
  return response.data;
});

export const getBots = createAsyncThunk("settings/get-bots", async (data) => {
  let response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api-admin/get-bots`
  );
  console.log(response.data);
  return response.data;
});

export const editBots = createAsyncThunk("settings/edit-bots", async (data) => {
  let response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api-admin/edit-bots`,
    data
  );
  console.log(response.data);
  return response.data;
});

export const editTechnicalWork = createAsyncThunk(
  "settings/edit-bots",
  async (data) => {
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api-admin/edit-technical-work`,
      data
    );
    console.log(response.data);
    return response.data;
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    editSettings: (state, action) => {

      const setting = state.settings.find(
        (setting) => setting.name === action.payload.name
      );
      if (setting) {
        // console.log('setting', setting)
        setting.value = action.payload.value;
        if (action.payload.is_activate !== undefined)
          setting.is_activate = action.payload.is_activate;
      } else {
        state.settings.push(action.payload);
      }
      // return [...state, action.payload]
    },
    editArrayBots: (state, action) => {

      console.log('action.payload', action.payload)
      state.bots = action.payload.map(bot => ({
        ...bot,
        chat_id: bot.chat_id.trim(),  // Пример очистки данных перед сохранением
        id: bot.id
      }));
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getSettings.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })

      .addCase(editSetting.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })

      .addCase(getSettings.fulfilled, (state, action) => {
        state.loading = "idle";
        state.settings = action.payload.data;
        state.error = null;
      })

      .addCase(getBots.fulfilled, (state, action) => {
        state.loading = "idle";
        state.bots = action.payload.data;
        state.error = null;
      })

      .addCase(editSetting.fulfilled, (state, action) => {
        state.loading = "idle";
        state.settings = action.payload.data;
        state.error = null;
      })

      .addCase(editBots.fulfilled, (state, action) => {
        state.loading = "idle";
        state.bots = action.payload.data;
        state.error = null;
      })

      .addCase(getSettings.rejected, (state, action) => {
        state.settings = [];
        state.loading = "failed";
        state.error = action.payload.code;
      })

      .addCase(editSetting.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.code;
      });
  },
});

// Action creators are generated for each case reducer function
export const { editSettings, editArrayBots } = settingsSlice.actions;

export default settingsSlice.reducer;
