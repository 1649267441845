import {Card, CardContent, Chip, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CustomToolbar from "../components/CustomToolbar";
import localeGrid from "../components/localeGrid";
import {getLogsRefferals} from "../store/slices/logSlice";
import "../styles/operation.css";
import {getSwappTransactions, getTransactions} from "../store/slices/transactionSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import CryptoSwappTransactions from "../components/SwappTransactions";
import axios from "axios";
import {merchantSlice} from "../store/slices/merchantSlice";
import LuckyPayTransactions from "../components/LuckyTransactions"; // Tell webpack that Button.js uses these styles

function MerchantsDetails() {
    const [merchant, setMerchant] = React.useState('crypto_swapp');

    const [swappBalance, setSwappBalance] = React.useState(0);
    const [luckyBalance, setLuckyBalance] = React.useState(0);
    const [tableHeight, setTableHeight] = React.useState(
        window.innerHeight - 200
    );
    const dispatch = useDispatch();
    React.useEffect(() => {

        resize();
        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    const resize = async () => {
        console.log(window.innerHeight);
        setTableHeight(window.innerHeight - 200);
    };

    React.useEffect(() => {
        if (process.env.NODE_ENV === 'production') {

            async function fetchSwappData() {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/api-admin/get-swapp-merchant-balance`)
                console.log(response.data.data)
                setSwappBalance(response.data.data)
            }

            fetchSwappData()
        }
    }, []);



    React.useEffect(() => {
        if (process.env.NODE_ENV === 'production') {

            async function fetchLuckyData() {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/api-admin/get-lucky-merchant-balance`)
                console.log(response.data.data)
                setLuckyBalance(response.data.data)
            }

            fetchLuckyData()
        }
    }, []);

    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">

                    <div className="d-flex justify-content-between">
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Merchant</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={merchant}
                                    label="Merchant"
                                    onChange={(event) => setMerchant(event.target.value)}
                                >
                                    <MenuItem value={'crypto_swapp'}>CryptoSwapp</MenuItem>
                                    <MenuItem value={"lucky_pay"}>LuckyPay</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className={' d-flex'}>
                            <Typography color="text.primary">
                                Баланс CryptoSwapp: <b>{swappBalance} ₽</b>
                            </Typography>
                            <span className={"mx-2"}>|</span>
                            <Typography color="text.primary">
                                Баланс LuckyPay: <b>{luckyBalance} ₽</b>
                            </Typography>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12 mt-3">
                            <div style={{height: tableHeight, width: "100%"}}>

                                {merchant === 'crypto_swapp' && <CryptoSwappTransactions/>}
                                {merchant === 'lucky_pay' && <LuckyPayTransactions/>}
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

export default MerchantsDetails;
