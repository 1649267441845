import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch, useSelector} from "react-redux";
import {setSnackBar} from "../store/slices/uiSlice";

export default function SnackBar() {
    // const [open, setOpen] = React.useState(false);

    const snackbar = useSelector((state) => state.ui.snackbar)
    const dispatch = useDispatch()

    // const handleClick = () => {
    //     setOpen(true);
    // };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        // setOpen(false);
        dispatch(setSnackBar({open: false, text: ""}))
    };

    const action = (
        <React.Fragment>
            {/*<Button color="secondary" size="small" onClick={handleClose}>*/}
            {/*    UNDO*/}
            {/*</Button>*/}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            {/*<Button onClick={handleClick}>Open simple snackbar</Button>*/}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={snackbar.text}
                action={action}
            />
        </div>
    );
}
