import {Checkbox, FormControlLabel, TextField, Typography,} from "@mui/material";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {editSettings} from "../store/slices/settingsSlice";

function RegulationSetting() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.settings);

  const { register, handleSubmit, control, watch, reset, setValue } = useForm({
    defaultValues: {
      id: null,
      name: "regulation",
      value: "",
    },
  });

    const watchForm = watch();

    React.useEffect(() => {
        dispatch(editSettings(watchForm));
    }, [watchForm]);

    React.useEffect(() => {
        let technical_work = settings.find(
          (item) => item.name === "regulation"
        );
        if (technical_work) {
          setValue("value", technical_work.value);
          setValue("id", technical_work.id);
        }
  }, []);


  return (


        <div className="row mt-1">
          <div className="mt-3">
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  {...field}
                  type="text"
                  rows={6}
                  multiline={true}
                  label={`Сообщение`}
                />
              )}
            />
          </div>
        </div>

  );
}

export default RegulationSetting;
