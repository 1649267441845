import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    requests: [],
    loading: 'IDLE',
    error: null
}

export const getRequests = createAsyncThunk(
    'request/get',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-admin/get-requests`, data)
        console.log(response.data)
        return response.data
    }
)


export const payedRequest = createAsyncThunk(
    'request/payed',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-request/payed`, data)
        console.log(response.data)
        return response.data
    }
)


export const requestSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(getRequests.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })


            .addCase(getRequests.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.requests = action.payload.data
                state.error = null;
            })


            .addCase(getRequests.rejected, (state, action) => {
                state.requests = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })

    }
})

// Action creators are generated for each case reducer function
export const {} = requestSlice.actions

export default requestSlice.reducer
