import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress, FormControl, FormControlLabel,
  FormLabel,
  IconButton, Radio, RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {DateRangePicker} from "@mui/x-date-pickers-pro/DateRangePicker";
import {getStatistics} from "../store/slices/transactionSlice";
import {Controller, useForm} from "react-hook-form";
import moment from "moment";

export default function UserDetails() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  let navigate = useNavigate();
  const [rangeDate, setRangeDate] = React.useState([null, null]);

  const {
    control: controlType,
    reset: resetType,
    watch: watchType,
  } = useForm({
    defaultValues: {
      type: "week",
    },
  });

  const type = watchType('type'); // you can supply default value as second argument


  const changeDate = (data) => {
    setRangeDate(data);
    console.log('data', data[0], data[1])

    if (type === 'calendar' && data[0] && data[1] && userId) {

      console.log('fetchUser')
      fetchUser(data); // Если даты не заданы, прерываем выполнение useEffect
    }
  };

  async function fetchUser(rangeDate) {
    try {
      let data;
      const today = moment(); // Текущая дата
      let startDate, endDate;

      if (type === 'calendar') {
        startDate = rangeDate[0];
        endDate = rangeDate[1];
      } else if (type === 'week') {
        startDate = today.clone().subtract(7, 'days'); // Сегодня минус 7 дней
        endDate = today;
      } else if (type === 'month') {
        startDate = today.clone().subtract(30, 'days'); // Сегодня минус 30 дней
        endDate = today;
      }

      if (startDate && endDate) {
        data = {
          userId: Number(userId),
          startDate: startDate.format('YYYY-MM-DD'), // Форматирование даты
          endDate: endDate.format('YYYY-MM-DD')
        };
        console.log("data", data)
      }


      let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-admin/get-user`, data);
      setUser(response.data.data);
    } catch (error) {
      console.error('Error loading storage details:', error);
    }
  }

  useEffect(() => {
    if (userId) fetchUser();
  }, [userId, type]);


  if (!user) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: 'calc(100vh - 80px)' }}>
        <CircularProgress/>
      </div>
    );
  }


  return (
      <Card className='p-4'>
        <div className="d-flex justify-content-between">
          <IconButton aria-label="delete" onClick={() => navigate(-1)}>
            <ArrowBackIcon/>
          </IconButton>
          <Typography className='text-left'>Пользователь {user.login} (#{user?.id})</Typography>
        </div>

        <div className="d-flex align-items-center justify-content-between p-4">
          <div className='d-flex align-items-center'>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Фильтр</FormLabel>

              <Controller
                  rules={{ required: true }}
                  control={controlType}
                  name="type"
                  render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                            value="week"
                            control={<Radio />}
                            label="Неделя"
                        />
                        <FormControlLabel
                            value="month"
                            control={<Radio />}
                            label="Месяц"
                        />
                        <FormControlLabel
                            value="calendar"
                            control={<Radio />}
                            label="Календарь"
                        />
                      </RadioGroup>
                  )}
              />
            </FormControl>

            {type == 'calendar' &&

                <div className='mx-4'>
                  <Typography color="text.primary" sx={{textAlign: 'left'}}>
                    Период:
                  </Typography>
                  <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      localeText={{start: "От", end: "До"}}
                      adapterLocale="ru"
                  >
                    <DateRangePicker
                        sx={{marginTop: '10px'}}
                        value={rangeDate}
                        onChange={(newValue) => changeDate(newValue)}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                              <TextField
                                  {...startProps}
                                  size="small"
                                  // sx={{width: '80px'}}
                              />
                              <Box sx={{mx: 2}}> - </Box>
                              <TextField
                                  {...endProps}
                                  size="small"
                                  // sx={{width: '80px'}}
                              />
                            </React.Fragment>
                        )}
                    />
                  </LocalizationProvider>
                </div>
            }


          </div>


          <div className="d-flex justify-content-center mt-5">
            <Card sx={{minWidth: 125, backgroundColor: 'rgba(94,165,255,0.15)'}} className='mx-2'>
              <CardContent>
                <Typography sx={{fontSize: 18, fontWeight: 'bold'}} color="text.secondary" gutterBottom>
                  Транзакции
                </Typography>
                <div className="d-flex">
                  <Typography variant="body2" className='mx-2'>
                    Количество:
                  </Typography>
                  <Typography variant="body2" className='mx-1 fw-bold'>
                    {user?.transactionsCount}
                  </Typography>
                </div>
                <div className="d-flex">
                  <Typography variant="body2" className='mx-2'>
                    Сумма (руб.):
                  </Typography>
                  <Typography variant="body2" className='mx-1 fw-bold'>
                    {user?.transactionsAmount} ₽
                  </Typography>
                </div>
                <div className="d-flex">
                  <Typography variant="body2" className='mx-2'>
                    Сумма (дол.):
                  </Typography>
                  <Typography variant="body2" className='mx-1 fw-bold'>
                    {user?.transactionsAmountUSDT} $
                  </Typography>
                </div>

              </CardContent>
            </Card>
            <Card sx={{minWidth: 125, backgroundColor: 'rgba(94,165,255,0.15)'}} className='mx-2'>
              <CardContent>
                <Typography sx={{fontSize: 18, fontWeight: 'bold'}} color="text.secondary" gutterBottom>
                  Выводы
                </Typography>
                <div className="d-flex">
                  <Typography variant="body2" className='mx-2'>
                    Количество:
                  </Typography>
                  <Typography variant="body2" className='mx-1 fw-bold'>
                    {user?.operationsCount}
                  </Typography>
                </div>

                <div className="d-flex">
                  <Typography variant="body2" className='mx-2'>
                    Сумма (дол.):
                  </Typography>
                  <Typography variant="body2" className='mx-1 fw-bold'>
                    {user?.operationsAmount} $
                  </Typography>
                </div>

              </CardContent>
            </Card>
          </div>

        </div>


      </Card>
  );
}
