import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    merchants: [],
    loading: 'IDLE',
    error: null
}

export const getMerchants = createAsyncThunk(
    'merchant/get',
    async (data) => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/api-admin/get-merchants`)
        console.log(response.data)
        return response.data
    }
)

export const editMerchants = createAsyncThunk(
    'merchant/edit',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-admin/edit-merchant`, data)
        console.log(response.data)
        return response.data
    }
)


export const merchantSlice = createSlice({
    name: 'merchant',
    initialState,
    reducers: {
        editArray: (state, action) => {

            let newMerchant = {...action.payload}
            // console.log('newMerchant', newMerchant)

            const merchant = state.merchants.find((merchant) => merchant.id === newMerchant.id)
            if (merchant) {
                for (const merchantKey in newMerchant) {
                    merchant[merchantKey] = Number(newMerchant[merchantKey])
                }
            }
        },
        updateMerchantOrder: (state, action) => {
            state.merchants = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(getMerchants.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })

            .addCase(editMerchants.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })

            .addCase(getMerchants.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.merchants = action.payload.data
                state.error = null;
            })

            .addCase(editMerchants.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.merchants = action.payload.data
                state.error = null;
            })


            .addCase(getMerchants.rejected, (state, action) => {
                state.merchants = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })

            .addCase(editMerchants.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.payload.code;
            })

    }
})

// Action creators are generated for each case reducer function
export const { editArray, updateMerchantOrder } = merchantSlice.actions;

export default merchantSlice.reducer
