import React from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Divider, Fade,
    FormControlLabel, IconButton, InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import {useForm, Controller} from "react-hook-form";
import {authorize, changePassword, editAddress, login} from "../store/slices/authSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {useDispatch, useSelector} from "react-redux";
import {setSnackBar} from "../store/slices/uiSlice";
import axios from "axios";

function ChangePassword() {
    const user = useSelector((state) => state.auth.user)
    const dispatch = useDispatch();


    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset
    } = useForm({

        defaultValues: {
            old_password: '',
            new_password: '',
            repeat_new_password: '',
        }
    });

    const onSubmit = data => {


        console.log(data)

        if (data.new_password != data.repeat_new_password) {
            dispatch(setSnackBar({
                open: true,
                text: 'Пароли не совпадают',
            }))
        } else {
            dispatch(changePassword(data))
                .then(unwrapResult)
                .then((result) => {
                    console.log('result', result); // => 233
                    dispatch(setSnackBar({
                        open: true,
                        text: 'Успешно',
                    }))
                    reset()

                })
                .catch((error) => {
                    dispatch(setSnackBar({
                        open: true,
                        text: 'Пароль не верный',
                    }))
                    reset()
                    console.error('error', error); // if there is an error
                });
        }


        // dispatch(changePassword(data))
    };


    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">
                    <Typography color="text.primary">
                        Смена пароля
                    </Typography>

                    <form className="mt-3">
                        <div>
                            <Controller
                                name="old_password"
                                control={control}
                                render={({field}) => <TextField
                                    type="password"
                                    sx={{width: '100%'}}
                                    {...field}
                                    label="Старый пароль"/>}
                            />
                        </div>

                        <div className="mt-3">
                            <Controller
                                name="new_password"
                                control={control}
                                render={({field}) => <TextField
                                    type="password"
                                    sx={{width: '100%'}}
                                    {...field}
                                    label="Новый пароль"/>}
                            />
                        </div>

                        <div className="mt-3">
                            <Controller
                                name="repeat_new_password"
                                control={control}
                                render={({field}) => <TextField
                                    type="password"
                                    sx={{width: '100%'}}
                                    {...field}
                                    label="Повторите новый пароль"/>}
                            />
                        </div>

                    </form>


                    {/*<Button onClick={() => dispatch(setSnackBar({open: true, text: 'test'}))}>*/}
                    {/*    test*/}
                    {/*</Button>*/}
                    <Button variant="outlined" className="mt-3"
                            onClick={handleSubmit(onSubmit)}>Сохранить</Button>
                </CardContent>
            </Card>
        </div>
    );
}

export default ChangePassword;
