import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  operations: [],
  loading: "IDLE",
  error: null,
};

// export const getOperations = createAsyncThunk("operation/get", async (data) => {
//   let response = await axios.get(
//     `${process.env.REACT_APP_API_URL}/api-admin/get-operations`
//   );
//   console.log(response.data);
//   return response.data;
// });

export const getOperations = createAsyncThunk(
  "operation/get",
  async (params) => {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api-admin/get-operations?page=${params.page}&perPage=${params.perPage}&newPerPage=${params.newPerPage}&searchTerm=${params.searchTerm}`
    );
    console.log(response.data);
    return response.data;
  }
);

export const deleteOperations = createAsyncThunk(
  "operation/delete",
  async (data) => {
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api-admin/delete-operations`,
      data
    );
    console.log(response.data);
    return response.data;
  }
);

export const editOperation = createAsyncThunk(
  "operation/edit",
  async (data) => {
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api-admin/edit-operation`,
      data
    );
    console.log(response.data);
    return response.data;
  }
);

export const operationSlice = createSlice({
  name: "token",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getOperations.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })

      .addCase(deleteOperations.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })
      .addCase(editOperation.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })

      .addCase(getOperations.fulfilled, (state, action) => {
        state.loading = "idle";
        state.operations = action.payload.data;
        state.error = null;
      })

      .addCase(deleteOperations.fulfilled, (state, action) => {
        state.loading = "idle";
        // state.operations = action.payload.data;
        state.error = null;
      })

      .addCase(editOperation.fulfilled, (state, action) => {
        state.loading = "idle";
        // state.operations = action.payload.data;
        state.error = null;
      })

      .addCase(getOperations.rejected, (state, action) => {
        state.operations = [];
        state.loading = "failed";
        state.error = action.payload.code;
      })

      .addCase(deleteOperations.rejected, (state, action) => {
        // state.operations = [];
        state.loading = "failed";
        state.error = action.payload.code;
      })
      .addCase(editOperation.rejected, (state, action) => {
        // state.operations = [];
        state.loading = "failed";
        state.error = action.payload.code;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = operationSlice.actions;

export default operationSlice.reducer;
