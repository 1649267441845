import {
  Box,
  Card,
  CardContent,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomToolbar from "../components/CustomToolbar";
import localeGrid from "../components/localeGrid";
import { getStatistics } from "../store/slices/transactionSlice";
import "../styles/Statistics.css";

function Statistics() {
  const [rangeDate, setRangeDate] = React.useState([null, null]);
  const [type, setType] = React.useState(false);

  const changeDate = (data) => {
    console.log("data", data);
    setRangeDate(data);

    dispatch(
      getStatistics({
        startDate: data[0],
        endDate: data[1],
      })
    );
    // console.log(window.innerHeight)
    // setTableHeight(window.innerHeight - 200)
  };

  const handleChangeType = (event) => {
    setType(event.target.checked);
  };

  const columns = [
    {
      field: "date",
      headerName: "Дата",
      width: 150,
    },
    {
      field: "amount",
      type: "number",
      headerName: "Сумма (RUB)",
      width: 120,
    },
    {
      field: "amount_usdt",
      type: "number",

      headerName: "Сумма (USDT)",
      width: 120,
    },
    {
      field: "transactions",
      type: "number",

      headerName: "Количество транзакций",
      width: 170,
    },
  ];
  // const [value, setValue] = React.useState([null, null]);

  const [tableHeight, setTableHeight] = React.useState(
    window.innerHeight - 200
  );
  const [tableWidth, setTableWidth] = React.useState(window.innerWidth - 50);

  const dispatch = useDispatch();
  const statistics = useSelector((state) => state.transaction.statistics);

  const resize = async () => {
    console.log(window.innerHeight);
    setTableHeight(window.innerHeight - 200);
    setTableWidth(window.innerWidth - 50);
  };

  React.useEffect(() => {
    setRangeDate([dayjs().subtract(1, "week"), dayjs()]);
    console.log(rangeDate);
    dispatch(
      getStatistics({
        startDate: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      })
    );
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const data = [
    {
      date: "23.01.2023",
      amount: 0,
      amount_usdt: 0,
      transactions: 0,
    },
    {
      date: "22.01.2023",
      amount: 453,
      amount_usdt: 30,
      transactions: 2,
    },
  ];

  const CustomTooltipChart = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Card sx={{ minWidth: 150 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {label}
            </Typography>

            <Typography variant="body2">
              <b>Транзакций: </b> {payload[0].payload.transactions}
            </Typography>
            <Typography variant="body2">
              <b>Сумма (RUB): </b> {payload[0].payload.amount.toFixed(2)}
            </Typography>
            <Typography variant="body2">
              <b>Сумма (USDT): </b> {payload[0].payload.amount_usdt.toFixed(2)}
            </Typography>
          </CardContent>
        </Card>
        // <div className="custom-tooltip">
        //     <p className="label">{label}</p>
        //     <p className="desc">{payload[0].value}</p>
        // </div>
      );
    }

    return null;
  };

  const renderLineChart = (
    <AreaChart
      width={tableWidth}
      height={tableHeight}
      data={statistics}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis allowDecimals={false} />
      <Tooltip content={<CustomTooltipChart />} />
      <Area
        type="monotone"
        dataKey="amount_usdt"
        stackId="1"
        stroke="#1976d2"
        fill="#1976D280"
      />
      {/*<Area*/}
      {/*    type="monotone"*/}
      {/*    dataKey="amount"*/}
      {/*    stackId="1"*/}
      {/*    stroke="#8884d8"*/}
      {/*    fill="#8884d8"*/}
      {/*/>*/}
      {/*<Area*/}
      {/*    type="monotone"*/}
      {/*    dataKey="amount_usdt"*/}
      {/*    stackId="1"*/}
      {/*    stroke="#82ca9d"*/}
      {/*    fill="#82ca9d"*/}
      {/*/>*/}
    </AreaChart>
  );

  return (
    <div>
      <Card sx={{ minWidth: 275 }}>
        <CardContent className="text-start p-3">
          <Typography color="text.primary">Статистика</Typography>

          <div className="row">
            <div className="col-12 justify-content-between d-flex align-items-center">
              <div>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>Таблица</Grid>
                  <Grid item>
                    <Switch checked={type} onChange={handleChangeType} />
                  </Grid>
                  <Grid item>График</Grid>
                </Grid>
              </div>
              <div className="d-flex align-items-center">
                <Typography color="text.primary" className="mx-1">
                  Период:
                </Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={{ start: "От", end: "До" }}
                  adapterLocale="ru"
                >
                  <DateRangePicker
                    value={rangeDate}
                    onChange={(newValue) => changeDate(newValue)}
                    renderInput={(startProps, endProps) => (
                      <React.Fragment>
                        <TextField
                          {...startProps}
                          size="small"
                          sx={{ width: 120 }}
                        />
                        <Box sx={{ mx: 2 }}> - </Box>
                        <TextField
                          {...endProps}
                          size="small"
                          sx={{ width: 120 }}
                        />
                      </React.Fragment>
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-3">
              <div style={{ height: tableHeight, width: "100%" }}>
                {type ? (
                  <div>{renderLineChart}</div>
                ) : (
                  <DataGridPremium
                    rowHeight={40}
                    rows={statistics}
                    getRowId={(row) => row.date}
                    columns={columns}
                    experimentalFeatures={{ aggregation: true }}
                    components={{ Toolbar: CustomToolbar }}
                    componentsProps={{
                      toolbar: {
                        filename: "Статистика",
                        showQuickFilter: true,
                      },
                    }}
                    initialState={{
                      aggregation: {
                        model: {
                          amount: "sum",
                          amount_usdt: "sum",
                          transactions: "sum",
                        },
                      },
                      sorting: {
                        sortModel: [
                          {
                            field: "created_at",
                            sort: "desc",
                          },
                        ],
                      },
                    }}
                    localeText={localeGrid}
                  />
                )}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Statistics;
