import React, {useState} from 'react';
import {
    Button,
    Card,
    CardContent, InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import {useForm, Controller} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {editSettings, editCommission, editSetting, getSettings} from "../store/slices/settingsSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import PercentIcon from '@mui/icons-material/Percent';

var _ = require('lodash');

function CommissionBitcoin() {
    const settings = useSelector((state) => state.settings.settings)
    const dispatch = useDispatch();
    const [commissionBitcoin, setCommissionBitcoin] = useState(0);




    const {
        register,
        control,
        handleSubmit,
        setValue,
        getValues,
        watch
    } = useForm({
        defaultValues: {
            id: null,
            name: 'commission_btc',
            value: 0,
        }
    });

    const watchCommissionBitcoin = watch(); // you can supply default value as second argument

    React.useEffect(() => {
        dispatch(editSettings(watchCommissionBitcoin))
    }, [watchCommissionBitcoin]);

    React.useEffect(() => {
        let commission;
        for (let i = 0; i < settings.length; i++) {
            if (settings[i].name == 'commission_btc') {
                commission = parseFloat(settings[i].value)
                setValue('value', commission)
                setValue('id', settings[i].id)
            }
        }
        setCommissionBitcoin(commission)
    }, [])


    const onSubmit = data => {
    };

    return (
        <div>
            <form className="mt-3">
                <div>
                    <Controller
                        name="value"
                        control={control}
                        render={({field}) =>
                            <TextField size="small" sx={{width: '100%'}}
                                       {...field}
                                       type="number"
                                       InputProps={{
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <PercentIcon/>
                                               </InputAdornment>
                                           ),
                                       }}
                                       label="Коммиссия (%)"/>
                        }
                    />
                </div>

            </form>
        </div>
    );
}

export default CommissionBitcoin;
